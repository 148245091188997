define(
({
  "no_data_message": "Keine Datensätze",
  "order_number": "Auftragsnr.",
  "elastic_order_number": "Elastic-Auftragsnr.",
  "line_number": "Zeilennummer",
  "customer_name": "Kundenname",
  "customer_number": "Kundennr.",
  "location_number": "Standortnr.",
  "location_name": "Standortname",
  "product": "Produktnr.",
  "style_name": "Produktname",
  "code": "Farbcode",
  "color": "Farbname",
  "status": "Zeilenstatus",
  "states": "Auftragsstatus",
  "tracking": "Trackingnr.",
  "parcel_number": "Paket#",
  "year": "Jahr",
  "season": "Saison",
  "order_date": "Erstellungsdatum",
  "cancel_date": "Abbrechen nach Datum",
  "invoice_date": "Rechnungsdatum",
  "invoice": "Rechnungsnr.",
  "invoice_url": "Rechnungs-URL",
  "download": "Herunterladen",
  "view": "Anzeigen",
  "ship_date": "Gewünschtes Versanddatum",
  "expected_ship_date": "Voraussichtliches Versanddatum",
  "terms_name": "Zahlungsbedingung",
  "po_number": "Kaufauftragsnr.",
  "size": "Größe",
  "alt_name": "Länge",
  "upc": "UPC",
  "units": "Menge",
  "amount": "Betrag",
  "net_price": "Netto",
  "unit_price": "Einheitspreis",
  "orders": "Aufträge",
  "totals": "Auftragssumme",
  "details": "Details",
  "open": "Öffnen",
  "shipped": "Versendet",
  "cancelled": "Abgebrochen",
  "canceled": "Abgebrochen",
  "released": "Freigegeben",
  "invoiced": "In Rechnung gestellt",
  "picked": "Ausgewählt",
  "region": "Region",
  "order_type": "Auftragsart",
  "backorder_date": "Rückstandsdatum",
  "backorder": "Rückstand",
  "payment_terms": "Zahlungsbedingungen",
  "ship_via": "Versenden mit",
  "line_discount": "Positionsrabatt",
  "rma_number": "Rücksendenr.",
  "cash_discount": "Barrabatt",
  "hold_for_confirmation": "Warten auf Bestätigung",
  "credit_hold": "Zurückhalten",
  "order_notes": "Bestellhinweise",
  "product_series": "Produktreihen",
  "product_category": "Produktkategorie",
  "product_sub_category": "Produktunterkategorie",
  "quantity_confirmed": "Bestätigt",
  "quantity_backordered": "Nachbestellt",
  "quantity_cancelled": "Abgebrochen",
  "quantity_shipped": "Versendet",
  "ivendix_order_id": "iVendix ID",
  "catalog": "Katalog",
  "source": "Quelle",
  "division": "Abteilung",
  "actual_ship_date": "Tatsächliches Versanddatum",
  "rep_name": "Name des Vertreters",
  "tracking_carrier": "Speditions-Code",
  "gross_amount": "Bruttobetrag",
  "discount_percent": "Rabatt %"
})
);
