define(
  ({
    app_name: 'Elastic',
    none: 'Keine',
    select: 'auswählen ...',
    cancel: 'Abbrechen',
    drop_ship_instructions: 'Nur Inlandsversand. Kein Versand an militärische Posteinrichtungen (APOs oder FPOs).',
    product_data_sheet_tab_technology: 'Technologie',
    product_data_sheet_tab_overview: 'Beschreibung',
    product_data_sheet_tab_details: 'Details',
    cart_submit_order_dealer: 'Auftrag absenden',
    cart_submit_order_rep: 'Auftrag absenden',
    erp_order_number: 'ERP-Auftragsnr.',
    sap_order_number: 'SAP-Auftragsnr.',
    nda_explanation: 'NDA-Erklärung.',
    terms_and_conditions_description: 'Untenstehend können Sie bestätigen, dass Sie die <a href="###" target="_blank">Nutzungsbedingungen</a> gelesen haben und ihnen zustimmen',
    discounted_total: 'Gesamter Rabatt',
    upcharge: 'Aufpreis',
    dont_show_me_this_again: 'Nicht erneut anzeigen',
    okay: 'OK',
    arrival_date: 'Gewünschtes Lieferdatum',
    sso_message: 'Externer Login',
    alt_login_title: 'Anmelden',
    prebook: 'Vororder',
    ats: 'ATS',
    alternate_images: 'Alternative Bilder',
    choose: 'Auswählen',
    new_version_available_modal: 'Neue Version verfügbar',
    new_version_available_message: 'Eine neue Version der App ist verfügbar',
    new_version_reload: 'Neue Version laden',
    new_version_snooze: 'Erinnern Sie mich später',
    new_version_save_and_reload: 'Meine Arbeit speichern und neu laden',
    popup_may_be_suppressed_title: 'Zahlungsseite geöffnet',
    popup_may_be_suppressed_description: 'Es wurde eine Seite für Kreditkartenzahlungen geöffnet. Wenn Sie sie nicht sehen, überprüfen Sie die Adressleiste Ihres Browsers auf ein blockiertes Popup und lassen Sie es zu.',
    popup_blocked_title: 'Popup blockiert',
    popup_blocked_description: 'Das neue Fenster zum Übermitteln der Zahlung wurde blockiert. Überprüfen Sie Ihre Browsereinstellungen und Erweiterungen und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, können Sie Ihre Bestellung speichern und Elastic in einem anderen Browser öffnen. Elastic wird am besten von aktuellen Versionen von Google Chrome unterstützt.',
    pay_by_credit_card: 'Mit Kreditkarte bezahlen',
    do_you_want_to_pay_by_credit_card: 'Möchten Sie mit Kreditkarte bezahlen?',
    pay_by_credit_card_no: 'Nein',
    pay_by_credit_card_yes: 'Ja',
    cancel_order_submission_and_payment: 'Auftragsübermittlung und Zahlung abbrechen',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'SKU (Vorjahr): %{reference}',
    variation_code: 'Variations-Code',
    pending_orders: 'Ausstehende Aufträge',
    freight_discount_message: 'Einige Artikel in dieser Sendung berechtigen zu einem Versandrabatt der Aktion %{sales_program}.',
    partial_freight_discount_message: 'Wählen Sie eine Versandmethode für Artikel in dieser Sendung aus, die vom Versandrabatt der Aktion %{sales_program} ausgenommen sind.',
    non_qualifying_items_ship_by_default_method: 'Artikel, die von der Aktion %{sales_program} ausgenommen sind, werden über die Standardmethode versendet.',
    export_pdf: 'PDF exportieren',
    ots: 'Ab Lager',
    terms_and_conditions: 'Nutzungsbedingungen',
    accept: 'Akzeptieren',
    back_to_orders: 'Zurück zu Aufträgen',
    back_to_payments: 'Zurück zu Zahlungen',
    back_to_shipments: 'Zurück zu Sendungen',
    required: 'Erforderlich',
    send_request: 'Anfrage senden',
    should_not_empty: 'darf nicht leer sein',
    should_be_number: 'sollte eine Zahl sein',
    incorrect_value: 'ungültiger Wert',
    incorrect_phone: 'Telefonnummer muss dem internationalen Format entsprechen +xx',
    success: 'Erfolgreich',
    error: 'Fehler',
    invalid_phone_number: 'Ungültige Telefonnummer',
    invalid_email: 'Ungültige E-Mail-Adresse',
    submit: 'Senden',
    success_message: 'Ihre Informationen wurden erfolgreich übermittelt',
    error_message: 'Beim Senden Ihrer Informationen ist ein Fehler aufgetreten. Versuchen Sie es erneut oder wenden Sie sich an den Kundenservice, wenn das Problem weiterhin besteht.',
    no_schema: 'Diese Funktion wurde nicht konfiguriert',
    reset: 'Zurücksetzen',
    quick_entry_table_header_shipment_b2c: 'Sendung',
    enter_quantities_segmented_control_b2c_by_shipment: 'Nach Sendung',
    new_document_b2c_shipment: 'Sendung',
    boxed_order: 'Auftrag in Karton',
    boxed_order_explanation_title: 'Aufträge in Karton',
    boxed_order_explanation: 'Wenn Sie mehr als einen Karton zu einer Bestellung hinzufügen, wirkt dies wie ein Mengenmultiplikator. Die eingegebenen Mengen werden über die Anzahl der eingegebenen Kartons dupliziert.',
    boxes: 'Kartons',
    units_per_box: 'Stück pro Karton',
    regional: 'Regional',
    purchase_order_tooltip: 'Der eingegebene Wert ist nicht gültig.',
    tournament_date_is_too_late: 'Datum muss am oder vor %{date} liegen',
    page_notes_error: 'Der eingegebene Wert ist nicht gültig.',
    internet_connection_lost: 'Internetverbindung unterbrochen',
    internet_connection_lost_builder_description: '<p>Sie sind nicht mit dem Internet verbunden. Bitte stellen Sie die Verbindung wieder her, um fortzufahren. Ihre Arbeit wurde bei der letzten manuellen oder automatischen Speicherung gespeichert.</p><p>Wenn Sie einen Datenverlust befürchten, klicken Sie bitte auf die Schaltfläche unten, um eine Sicherungsdatei herunterzuladen, und leiten Sie die Datei an <Link>support@elasticsuite.com</Link> weiter, um Ihre Bestellung wiederherzustellen.</p>',
    internet_connection_lost_description: 'Sie sind derzeit nicht mit dem Internet verbunden. Bitte Verbindung wieder herstellen, um fortzufahren.',
    download_backup_data_file: 'Sicherungsdatei herunterladen',
    submit_order_description: 'Ihr Auftrag wird in Kürze übermittelt. Er ist nach der Übermittlung gesperrt und kann nicht länger bearbeitet werden. Bitte beachten Sie, dass der Auftrag in der Cloud gespeichert wird.',
  }),
);
