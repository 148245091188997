define({
 root: ({
  "no_data_message": "No Records",
  "order_number": "ERP Order #",
  "elastic_order_number": "Elastic Order #",
  "line_number": "Line #",
  "customer_name": "Customer Name",
  "customer_number": "Customer #",
  "location_number": "Location #",
  "location_name": "Location Name",
  "product": "Product #",
  "style_name": "Product Name",
  "code": "Color Code",
  "color": "Color Name",
  "status": "Line Status",
  "states": "Order Status",
  "tracking": "Tracking #",
  "parcel_number": "Parcel #",
  "year": "Year",
  "season": "Season",
  "order_date": "Date Created",
  "cancel_date": "Cancel By Date",
  "invoice_date": "Invoice Date",
  "invoice": "Invoice #",
  "invoice_url": "Invoice URL",
  "download": "Download",
  "view": "View",
  "ship_date": "Requested Ship Date",
  "expected_ship_date": "Expected Ship Date",
  "terms_name": "Terms Name",
  "po_number": "PO #",
  "size": "Size",
  "alt_name": "Size Length",
  "upc": "UPC",
  "units": "Qty",
  "amount": "Amount",
  "net_price": "Net",
  "unit_price": "Unit Price",
  "net_retail_price": "Net Retail Price",
  "orders": "Orders",
  "totals": "Totals",
  "details": "Details",
  "open": "Open",
  "shipped": "Shipped",
  "cancelled": "Cancelled",
  "canceled": "Cancelled",
  "released": "Released",
  "invoiced": "Invoiced",
  "picked": "Picked",
  "region": "Region",
  "order_type": "Order Type",
  "backorder_date": "Backorder Date",
  "backorder": "Backorder",
  "payment_terms": "Payment Terms",
  "ship_via": "Ship Via",
  "line_discount": "Line Discount",
  "rma_number": "RMA #",
  "cash_discount": "Cash Discount",
  "hold_for_confirmation": "Hold For Confirmation",
  "credit_hold": "Credit Hold",
  "order_notes": "Order Notes",
  "product_series": "Product Series",
  "product_category": "Product Category",
  "product_sub_category": "Product Subcategory",
  "quantity_confirmed": "Confirmed",
  "quantity_backordered": "Backordered",
  "quantity_cancelled": "Cancelled",
  "quantity_shipped": "Shipped",
  "ivendix_order_id": "iVendix ID",
  "catalog": "Catalog",
  "source": "Source",
  "division": "Division",
  "actual_ship_date": "Actual Ship Date",
  "rep_name": "Rep Name",
  "tracking_carrier": "Carrier Code",
  "gross_amount": "Gross Amount",
  "discount_percent": "Discount %",
  "internal_code": "Internal Code",
  "requested_date": "Requested Date",
  "net_amount_total": "Net Amount Total",
  "freight_amount": "Freight Amount",
  "client_order_status": "Order Status",
  "ship_address": "Ship Address",
  "ship_city": "Ship City",
  "ship_state": "Ship State",
  "warehouse_name": "Warehouse Name",
  "client_carrier_code": "Client Carrier Code",
  "discount_1": "Discount 1",
  "discount_2": "Discount 2",
  "discount_3": "Discount 3",
  "brand": "Brand",
  "quantity_open": "Quantity Open",
  "amount_open": "Amount Open",
  "quantity_invoiced": "Quantity Invoiced",
  "amount_invoiced": "Amount Invoiced",
  "promo_code": "Promo Code",
  "size_code": "Size Code",
  "customer_name": "Customer Name",
  "due_date": "Due Date",
  "quantity_ordered": "Quantity Ordered",
  "quantity_allocated": "Quantity Allocated",
  "available_date": "Available Date",
  "bank_information": "Bank Information",
  "shipment_number": "Shipment #",
  "quantity_shipped_sku": "Shipped SKU",
  "name": "Name",
}),
  "zh-tw": true,
  "zh": true,
  "ja": true,
  "fr": true,
  "de": true,
  "ko": true,
  "it": true,
  "cs": true,
  "tr": true,
  "pl": true,
  "fi": true,
  "hu": true,
  "sk": true,
  "nb": true,
  "es": true,
  "nl": true,
  "pt": true,
  "ru": true,
  "sv": true
});
