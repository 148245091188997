define(
({
  "no_data_message": "Sem registos",
  "order_number": "Encomenda N.º",
  "elastic_order_number": "Encomenda Elastic N.º",
  "line_number": "Linha N.º",
  "customer_name": "Nome do cliente",
  "customer_number": "Cliente N.º",
  "location_number": "Localização N.º",
  "location_name": "Nome da localização",
  "product": "Produto N.º",
  "style_name": "Nome do produto",
  "code": "Código de cor",
  "color": "Nome da cor",
  "status": "Estado da linha",
  "states": "Estado da encomenda",
  "tracking": "Número de seguimento",
  "parcel_number": "Parcela N.º",
  "year": "Ano",
  "season": "Época",
  "order_date": "Data de criação",
  "cancel_date": "Cancelado na data",
  "invoice_date": "Data da fatura",
  "invoice": "Fatura N.º",
  "invoice_url": "URL da fatura",
  "download": "Transferir",
  "view": "Ver",
  "ship_date": "Data de envio pedida",
  "expected_ship_date": "Data estimada para envio",
  "terms_name": "Nome dos termos",
  "po_number": "N.º de caixa postal",
  "size": "Tamanho",
  "alt_name": "Comprimento",
  "upc": "UPC",
  "units": "Qntd.",
  "amount": "Quantia",
  "net_price": "Líquido",
  "unit_price": "Preço unitário",
  "orders": "Encomendas",
  "totals": "Totais",
  "details": "Detalhes",
  "open": "Abrir",
  "shipped": "Enviado",
  "cancelled": "Cancelado",
  "canceled": "Cancelado",
  "released": "Lançado",
  "invoiced": "Faturado",
  "picked": "Recolhido",
  "region": "Região",
  "order_type": "Tipo de encomenda",
  "backorder_date": "Data do atraso",
  "backorder": "Atraso",
  "payment_terms": "Termos de pagamento",
  "ship_via": "Enviar por",
  "line_discount": "Desconto de linha",
  "rma_number": "RMA N.º",
  "cash_discount": "Desconto em dinheiro",
  "hold_for_confirmation": "Aguarde por confirmação",
  "credit_hold": "Reserva de crédito",
  "order_notes": "Notas da encomenda",
  "product_series": "Série do produto",
  "product_category": "Categoria do produto",
  "product_sub_category": "Subcategoria do produto",
  "quantity_confirmed": "Confirmado",
  "quantity_backordered": "Atrasada",
  "quantity_cancelled": "Cancelado",
  "quantity_shipped": "Enviado",
  "ivendix_order_id": "ID iVendix",
  "catalog": "Catálogo",
  "source": "Origem",
  "division": "Divisão",
  "actual_ship_date": "Data real de envio",
  "rep_name": "Nome do rep.",
  "tracking_carrier": "Código da transportadora",
  "gross_amount": "Montante ilíquido",
  "discount_percent": "% de desconto"
})
);
