define(
({
  "no_data_message": "無紀錄",
  "order_number": "訂單 #",
  "elastic_order_number": "彈性訂單 #",
  "line_number": "產品線 #",
  "customer_name": "客戶名稱",
  "customer_number": "客戶 #",
  "location_number": "位置 #",
  "location_name": "位置名稱",
  "product": "產品 #",
  "style_name": "產品名稱",
  "code": "色彩編碼",
  "color": "色彩名稱",
  "status": "產品線狀態",
  "states": "訂單狀態",
  "tracking": "追蹤號 #",
  "parcel_number": "包裹 #",
  "year": "年度",
  "season": "季度",
  "order_date": "創建日期",
  "cancel_date": "此日期前取消",
  "invoice_date": "單據日期",
  "invoice": "單據 #",
  "invoice_url": "單據連結",
  "download": "下載",
  "view": "查看",
  "ship_date": "請求貨運日期",
  "expected_ship_date": "預期貨運日期",
  "terms_name": "條件名稱",
  "po_number": "付款委託 #",
  "size": "尺寸",
  "alt_name": "尺寸長度",
  "upc": "UPC",
  "units": "數量",
  "amount": "數量",
  "net_price": "淨計",
  "unit_price": "單價",
  "orders": "訂單",
  "totals": "總計",
  "details": "細節",
  "open": "開啟",
  "shipped": "已發貨",
  "cancelled": "已取消",
  "canceled": "已取消",
  "released": "已釋放",
  "invoiced": "已發單據",
  "picked": "已剔選",
  "region": "地域",
  "order_type": "訂單種類",
  "backorder_date": "缺貨訂購日期",
  "backorder": "缺貨訂購",
  "payment_terms": "付款條件",
  "ship_via": "貨運方式",
  "line_discount": "產品線折扣",
  "rma_number": "RMA #",
  "cash_discount": "現金折扣",
  "hold_for_confirmation": "長按以供確認",
  "credit_hold": "保留信用額",
  "order_notes": "訂單備忘",
  "product_series": "產品系列",
  "product_category": "產品種類",
  "product_sub_category": "產品細類",
  "quantity_confirmed": "確認",
  "quantity_backordered": "已缺貨訂購",
  "quantity_cancelled": "已取消",
  "quantity_shipped": "已發貨",
  "ivendix_order_id": "iVendix ID",
  "catalog": "目錄",
  "source": "源頭",
  "division": "分部",
  "actual_ship_date": "確實貨運日期",
  "rep_name": "代理名稱",
  "tracking_carrier": "運貨者編碼",
  "gross_amount": "總數量",
  "discount_percent": "折扣％"
})
);
