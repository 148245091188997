define(
  ({
    copy: 'Copia',
    paste: 'Incolla',
    copyToAll: 'Copia Tutto',
    add_to_cart: 'Aggiungi al carrello',
    addToCart: 'Aggiungi al Carrello',
    remove: 'Rimuovi',
    added: 'Aggunto',
    dropped: 'Eliminato',
    units: 'Unità',
    total: 'Totale',
    atsWarning: 'Questa quantità non sarà disponibile fino a %{availableOn}.',
    inventory_warehouse: 'Inventario magazzino',
    notInCatalog: 'Impossibile aggiungere al carrello',
    notInCatalogExplanation: 'Spiacenti, ma non è possibile aggiungere articoli tra cataloghi.',
    digital_market_notInCatalogExplanation: 'Il tuo carrello contiene solo articoli di un brand per volta',
    removeFromCartSuggestion: 'Desideri <link>svuotare il carrello?</link>',
    digital_market_removeFromCartSuggestion: 'Puoi <link>svuotare il carrello</link> per aggiungere questo articolo',
    emptyCart: 'Svuota il carrello',
    empty: 'Svuota',
    emptyConfirmation: 'Vuoi davvero svuotare il carrello?',
    available_on: 'Disponibile il',
    personalization: 'Personalizzazione',
    personalize: 'Personalizza',
    placement: 'Posizionamento',
    content: 'Contenuto',
    color: 'Colore',
    product_specification: 'Specifiche del prodotto',
    type: 'Tipo',
    logo: 'Logo',
    text: 'Testo',
    text_and_logo: 'Testo, Logo',
    other: 'Altro',
    value_might_be_stale: "A causa delle modifiche nel tuo carrello, questo valore potrebbe non essere più valido. Salva l'ordine per ricalcolare.",
    more_details: 'Altri dettagli',
    availability: 'Disponibilità',
    no_scheduled_availability: 'Nessuna disponibilità programmata',
    not_available_until: 'Non disponibile fino al <date />',
    available_as_of: 'Disponibile dal <date />',
    units_bundled_singular_label: '%{units} unità raggruppate',
    units_bundled_label: '%{units} unità raggruppate',
    add_bundle_to_cart: 'Aggiungi pacchetto al carrello',
    bundles: 'Pacchetti',
    bundled: 'Raggruppato',
    product: 'Prodotto',
    sizes_and_quantities: 'Dimensioni/Quantità',
    units_singular_label: '%{units} unità',
    units_label: '%{units} unità',
    current_availability: 'Disponibilità attuale',
    future_availability: 'Disponibilità futura',
    size: 'Taglia',
    sku: 'SKU',
    availability_date: 'Data di disponibilità',
    quantity: 'Qtà',
    availability_subject_to_change: 'La disponibilità può variare',
    yes: 'Sì',
    no: 'No',
    copy_to_all_confirmation_message: 'Vuoi davvero copiare questo intervallo di taglie a tutti gli stili qui sotto?',
  }),
);
