define({
  root: ({
    copy: 'Copy',
    paste: 'Paste',
    copyToAll: 'Copy to All',
    add_to_cart: 'Add to Cart',
    addToCart: 'Add to Cart',
    remove: 'Remove',
    added: 'Added',
    dropped: 'Dropped',
    units: 'Units',
    total: 'Total',
    atsWarning: 'This quantity will not be available by %{availableOn}.',
    inventory_warehouse: 'Inventory Warehouse',
    notInCatalog: 'Unable to Add Item to Cart',
    notInCatalogExplanation: 'Sorry, but adding items across catalogs is not available.',
    digital_market_notInCatalogExplanation: 'Your cart can only contain items from one brand at a time',
    removeFromCartSuggestion: 'Would you like to <link>empty your cart?</link>',
    digital_market_removeFromCartSuggestion: 'You can <link>empty your cart</link> to add this item',
    emptyCart: 'Empty Cart',
    empty: 'Empty',
    emptyConfirmation: 'Are you sure you want to empty your cart?',
    available_on: 'Available On',
    personalization: 'Personalization',
    personalize: 'Personalize',
    configure: 'Configure',
    placement: 'Placement',
    content: 'Content',
    color: 'Color',
    product_specification: 'Product specification',
    type: 'Type',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, Logo',
    other: 'Other',
    value_might_be_stale: 'Because of changes to your cart, this value might be out of date. Save your order to recalculate.',
    more_details: 'More Details',
    availability: 'Availability',
    no_scheduled_availability: 'No Scheduled Availability',
    not_available_until: 'Not available until <date />',
    available_as_of: 'Available as of <date />',
    units_bundled_singular_label: '%{units} Unit Bundled',
    units_bundled_label: '%{units} Units Bundled',
    add_bundle_to_cart: 'Add Bundle to Cart',
    bundles: 'Bundles',
    bundled: 'Bundled',
    product: 'Product',
    sizes_and_quantities: 'Size / Quantity',
    units_singular_label: '%{units} Unit',
    units_label: '%{units} Units',
    current_availability: 'Current Availability',
    future_availability: 'Future Availability',
    size: 'Size',
    sku: 'SKU',
    availability_date: 'Availability Date',
    quantity: 'Qty.',
    availability_subject_to_change: 'Availability is subject to change',
    yes: 'Yes',
    no: 'no',
    copy_to_all_confirmation_message: 'Are you sure you want to copy this size range to all styles below?',
  }),
  'zh-tw': true,
  zh: true,
  ja: true,
  fr: true,
  de: true,
  ko: true,
  it: true,
  cs: true,
  tr: true,
  pl: true,
  fi: true,
  hu: true,
  sk: true,
  nb: true,
  es: true,
  nl: true,
  pt: true,
  ru: true,
  sv: true,
});
