define(
({
  "no_data_message": "Kayıt yok",
  "order_number": "Sipariş #",
  "elastic_order_number": "Elastik Sipariş #",
  "line_number": "Hat #",
  "customer_name": "Müşteri Adı",
  "customer_number": "Müşteri #",
  "location_number": "Konum #",
  "location_name": "Konum Adı",
  "product": "Ürün #",
  "style_name": "Ürün Adı",
  "code": "Renk Kodu",
  "color": "Renk Adı",
  "status": "Hat Durumu",
  "states": "Sipariş Durumu",
  "tracking": "Takip #",
  "parcel_number": "Paket #",
  "year": "Yıl",
  "season": "Sezon",
  "order_date": "Oluşturulduğu Tarih",
  "cancel_date": "Tarihe Göre İptal",
  "invoice_date": "Fatura Tarihi",
  "invoice": "Fatura #",
  "invoice_url": "Fatura URL'si",
  "download": "İndir",
  "view": "Görüntüle",
  "ship_date": "Talep Edilen Gönderme Tarihi",
  "expected_ship_date": "Beklenen Gönderme Tarihi",
  "terms_name": "Koşullar Adı",
  "po_number": "PO #",
  "size": "Boyut",
  "alt_name": "Boyut Uzunluğu",
  "upc": "UPC",
  "units": "Mkt",
  "amount": "Tutar",
  "net_price": "Net",
  "unit_price": "Birim Fiyat",
  "orders": "Siparişler",
  "totals": "Toplamlar",
  "details": "Ayrıntılar",
  "open": "Aç",
  "shipped": "Gönderildi",
  "cancelled": "İptal Edildi",
  "canceled": "İptal Edildi",
  "released": "Bırakıldı",
  "invoiced": "Faturalandı",
  "picked": "Alındı",
  "region": "Bölge",
  "order_type": "Sipariş Türü",
  "backorder_date": "Geri Sipariş Tarihi",
  "backorder": "Geri Sipariş",
  "payment_terms": "Ödeme Koşulları",
  "ship_via": "Şununla Gönder:",
  "line_discount": "Hat İndirimi",
  "rma_number": "RMA #",
  "cash_discount": "Nakit İndirimi",
  "hold_for_confirmation": "Doğrulama İçin Tut",
  "credit_hold": "Kredi Askıda",
  "order_notes": "Sipariş Notları",
  "product_series": "Ürün Serileri",
  "product_category": "Ürün Kategorisi",
  "product_sub_category": "Ürün Alt Kategorisi",
  "quantity_confirmed": "Doğrulandı",
  "quantity_backordered": "Geri Sipariş Edildi",
  "quantity_cancelled": "İptal Edildi",
  "quantity_shipped": "Gönderildi",
  "ivendix_order_id": "iVendix Kimliği",
  "catalog": "Katalog",
  "source": "Kaynak",
  "division": "Bölüm",
  "actual_ship_date": "Gerçek Gönderme Tarihi",
  "rep_name": "Temsilci Adı",
  "tracking_carrier": "Taşıyıcı Kodu",
  "gross_amount": "Brüt Tutar",
  "discount_percent": "İndirim %"
})
);
