define(
  ({
    copy: 'Kopieren',
    paste: 'Einfügen',
    copyToAll: 'In alle kopieren',
    add_to_cart: 'In den Warenkorb legen',
    addToCart: 'In den Warenkorb',
    remove: 'Entfernen',
    added: 'Hinzugefügt',
    dropped: 'Entfernt',
    units: 'Stücke',
    total: 'Gesamt',
    atsWarning: 'Diese Menge wird bis %{availableOn} nicht verfügbar sein.',
    inventory_warehouse: 'Bestandslager',
    notInCatalog: 'Artikel konnte nicht zum Warenkorb hinzugefügt werden',
    notInCatalogExplanation: 'Sorry, aber das Hinzufügen von Artikeln aus verschiedenen Katalogen ist nicht möglich.',
    digital_market_notInCatalogExplanation: 'Ihr Warenkorb kann jeweils nur Artikel einer Marke enthalten',
    removeFromCartSuggestion: 'Wirklich <link>Ihren Warenkorb leeren?</link>',
    digital_market_removeFromCartSuggestion: 'Sie können <link>Ihren Warenkorb leeren</link>, um diesen Artikel hinzuzufügen',
    emptyCart: 'Warenkorb leeren',
    empty: 'Leeren',
    emptyConfirmation: 'Wirklich Ihren Warenkorb leeren?',
    available_on: 'Verfügbar am',
    personalization: 'Personalisierung',
    personalize: 'Personalisieren',
    placement: 'Platzierung',
    content: 'Inhalt',
    color: 'Farbe',
    product_specification: 'Produktspezifikation',
    type: 'Typ',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, Logo',
    other: 'Sonstiges',
    value_might_be_stale: 'Aufgrund von Änderungen in Ihrem Warenkorb ist dieser Wert möglicherweise nicht mehr aktuell. Speichern Sie Ihre Bestellung, um sie neu zu berechnen.',
    more_details: 'Mehr Details',
    availability: 'Verfügbarkeit',
    no_scheduled_availability: 'Keine geplante Verfügbarkeit',
    not_available_until: 'Nicht verfügbar bis <date />',
    available_as_of: 'Verfügbar ab <date />',
    units_bundled_singular_label: '%{units} Einheit im Paket',
    units_bundled_label: '%{units} Einheiten im Paket',
    add_bundle_to_cart: 'Paket zum Warenkorb hinzufügen',
    bundles: 'Pakete',
    bundled: 'Gebündelt',
    product: 'Produkt',
    sizes_and_quantities: 'Größe / Menge',
    units_singular_label: '%{units} Stück',
    units_label: '%{units} Stücke',
    current_availability: 'Aktuelle Verfügbarkeit',
    future_availability: 'Zukünftige Verfügbarkeit',
    size: 'Größe',
    sku: 'SKU',
    availability_date: 'Datum der Verfügbarkeit',
    quantity: 'Menge',
    availability_subject_to_change: 'Verfügbarkeit kann sich ändern',
    yes: 'Ja',
    no: 'Nein',
    copy_to_all_confirmation_message: 'Wirklich diesen Größenbereich in alle folgenden Stile kopieren?',
  }),
);
