define(
({
  "no_data_message": "Nincs nyilvántartás",
  "order_number": "Rendelési szám",
  "elastic_order_number": "Elastic rendelési szám",
  "line_number": "Vonalszám",
  "customer_name": "Vevő neve",
  "customer_number": "Vevőszám",
  "location_number": "Hely száma",
  "location_name": "Hely neve",
  "product": "Termékszám",
  "style_name": "Terméknév",
  "code": "Színkód",
  "color": "Szín neve",
  "status": "Vonal státusz",
  "states": "Rendelés állapota",
  "tracking": "Nyomkövetési szám",
  "parcel_number": "Csomagszám",
  "year": "Év",
  "season": "Szezon",
  "order_date": "Létrehozás dátuma",
  "cancel_date": "Visszavonás eddig dátuma",
  "invoice_date": "Számla dátuma",
  "invoice": "Számlaszám",
  "invoice_url": "Számla URL",
  "download": "Letöltés",
  "view": "Nézet",
  "ship_date": "Igényelt szállítási dátum",
  "expected_ship_date": "Várható szállítási dátum",
  "terms_name": "Feltételek neve",
  "po_number": "Rendelési szám",
  "size": "Méret",
  "alt_name": "Méret hossz",
  "upc": "UPC",
  "units": "Menny.",
  "amount": "Összeg",
  "net_price": "Nettó",
  "unit_price": "Egységár",
  "orders": "Rendelések",
  "totals": "Összesítések",
  "details": "Részletek",
  "open": "Nyitott",
  "shipped": "Kiszállított",
  "cancelled": "Törölt",
  "canceled": "Törölt",
  "released": "Felszabadított",
  "invoiced": "Kiszámlázott",
  "picked": "Felvett",
  "region": "Régió",
  "order_type": "Rendelés típusa",
  "backorder_date": "Rendelési hátralék dátuma",
  "backorder": "Rendelési hátralék",
  "payment_terms": "Fizetési feltételek",
  "ship_via": "Szállító:",
  "line_discount": "Vonal kedvezmény",
  "rma_number": "RMA-szám",
  "cash_discount": "Készpénzes kedvezmény",
  "hold_for_confirmation": "Visszatartás megerősítéshez",
  "credit_hold": "Hitel visszatartás",
  "order_notes": "Rendelési jegyzetek",
  "product_series": "Terméksorozat",
  "product_category": "Termékkategória",
  "product_sub_category": "Termék alkategória",
  "quantity_confirmed": "Megerősítve",
  "quantity_backordered": "Rendelési hátralék",
  "quantity_cancelled": "Visszavont",
  "quantity_shipped": "Kiszállítva",
  "ivendix_order_id": "iVendix-azonosító",
  "catalog": "Katalógus",
  "source": "Forrás",
  "division": "Divízió",
  "actual_ship_date": "Tényleges szállítási dátum",
  "rep_name": "Képviselő neve",
  "tracking_carrier": "Fuvarozó kódja",
  "gross_amount": "Bruttó összeg",
  "discount_percent": "Kedvezmény %"
})
);
