define(
({
  "no_data_message": "Sin registros",
  "order_number": "# pedido",
  "elastic_order_number": "Pedido Elastic #",
  "line_number": "Línea #",
  "customer_name": "Nombre de cliente",
  "customer_number": "# cliente",
  "location_number": "# ubicación",
  "location_name": "Nombre de ubicación",
  "product": "# producto",
  "style_name": "Nombre del producto",
  "code": "Código de color",
  "color": "Nombre de color",
  "status": "Estado de línea",
  "states": "Estado de pedido",
  "tracking": "# seguimiento",
  "parcel_number": "Paquete n.º",
  "year": "Año",
  "season": "Temporada",
  "order_date": "Fecha de creación",
  "cancel_date": "Cancelar por fecha",
  "invoice_date": "Fecha de factura",
  "invoice": "# factura",
  "invoice_url": "URL de factura",
  "download": "Descargar",
  "view": "Ver",
  "ship_date": "Fecha de envío solicitada",
  "expected_ship_date": "Fecha de envío esperada",
  "terms_name": "Nombre de términos",
  "po_number": "# PO",
  "size": "Tamaño",
  "alt_name": "Longitud de la talla",
  "upc": "CUP",
  "units": "Cant.",
  "amount": "Cantidad",
  "net_price": "Neto",
  "unit_price": "Precio por unidad",
  "orders": "Pedidos",
  "totals": "Totales",
  "details": "Detalles",
  "open": "Abrir",
  "shipped": "Enviado",
  "cancelled": "Cancelado",
  "canceled": "Cancelado",
  "released": "Aprobado",
  "invoiced": "Facturados",
  "picked": "Recogido",
  "region": "Región",
  "order_type": "Tipo de pedido",
  "backorder_date": "Fecha de pedido anterior",
  "backorder": "Pedido anterior",
  "payment_terms": "Términos del pago",
  "ship_via": "Enviar por",
  "line_discount": "Descuento Line",
  "rma_number": "# RMA",
  "cash_discount": "Descuento en metálico",
  "hold_for_confirmation": "Espera para confirmación",
  "credit_hold": "Suspensión de crédito",
  "order_notes": "Notas de pedido",
  "product_series": "Serie de productos",
  "product_category": "Categoría de producto",
  "product_sub_category": "Subcategoría de producto",
  "quantity_confirmed": "Confirmado",
  "quantity_backordered": "Pedido pendiente",
  "quantity_cancelled": "Cancelado",
  "quantity_shipped": "Enviado",
  "ivendix_order_id": "ID iVendix",
  "catalog": "Catálogo",
  "source": "Fuente",
  "division": "División",
  "actual_ship_date": "Fecha de envío real",
  "rep_name": "Nombre de repetición",
  "tracking_carrier": "Código de transportista",
  "gross_amount": "Cantidad bruta",
  "discount_percent": "Descuento %"
})
);
