define(
  ({
    app_name: '유동적',
    none: '없음',
    select: '선택...',
    cancel: '취소',
    drop_ship_instructions: '국내 배송 한정. APO나 FPO 배송은 불가능합니다.',
    product_data_sheet_tab_technology: '기술',
    product_data_sheet_tab_overview: '개요',
    product_data_sheet_tab_details: '상세 내용',
    cart_submit_order_dealer: '주문하기',
    cart_submit_order_rep: '주문하기',
    erp_order_number: 'ERP 주문 #',
    sap_order_number: 'SAP 주문 #',
    nda_explanation: 'NDA 설명.',
    terms_and_conditions_description: '아래를 체크하여 <a href="###" target="_blank">이용 약관</a>을 읽고 동의하는지 표시하세요.',
    discounted_total: '할인된 합계',
    upcharge: '충전 해제',
    dont_show_me_this_again: '다시 표시하지 않음.',
    okay: '확인',
    arrival_date: '배송 시작 날짜',
    sso_message: '외부 로그인',
    alt_login_title: '로그인',
    prebook: '예약',
    ats: 'ATS',
    alternate_images: '대체 이미지',
    choose: '선택',
    new_version_available_modal: '새 버전 사용 가능',
    new_version_available_message: '애플리케이션의 새 버전을 사용할 수 있습니다',
    new_version_reload: '새 버전 로드',
    new_version_snooze: '나중에 알림 받기',
    new_version_save_and_reload: '내 작업을 저장하고 다시 로드하기',
    popup_may_be_suppressed_title: '결재 페이지 열림',
    popup_may_be_suppressed_description: '신용 카드 결재 페이지가 열렸습니다. 보이지 않으면 팝업이 차단된 프라우저의 주소란을 확인하고 이를 허용하세요.',
    popup_blocked_title: '팝업 차단됨',
    popup_blocked_description: '결재 제출을 위한 새 창이 차단되었습니다. 브라우저 설정과 확장자를 확인하고 다시 시도하세요. 문제가 계속되면 주문을 저장하고 다른 브라우저에서 Elastic을 열면 됩니다. Elastic은 Google Chrome 최신 버전에서 가장 잘 지원됩니다.',
    pay_by_credit_card: '신용카드로 결재',
    do_you_want_to_pay_by_credit_card: '신용카드로 결재하시겠습니까?',
    pay_by_credit_card_no: '아니오',
    pay_by_credit_card_yes: '예',
    cancel_order_submission_and_payment: '주문 제출 및 결재 취소',
    product_reference_short: '참조: %{reference}',
    product_reference_long: '전년도 SKU: %{reference}',
    variation_code: '변형 코드',
    pending_orders: '대기 중 주문',
    freight_discount_message: '이 배송에 포함된 항목은 %{sales_program} 배송 할인 대상입니다',
    partial_freight_discount_message: '이 배송에 포함되는 항목 중 %{sales_program} 배송 할인 대상이 아닌 항목에 대한 배송 방법을 선택하세요',
    non_qualifying_items_ship_by_default_method: '%{sales_program} 대상이 아니었던 항목은 기본 방법으로 배송됩니다',
    export_pdf: 'PDF 내보내기',
    ots: 'OTS',
    terms_and_conditions: '사용 약관',
    accept: '동의',
    back_to_orders: '주문으로 돌아가기',
    back_to_payments: '결재로 돌아가기',
    back_to_shipments: '배송으로 돌아가기',
    required: '필수',
    send_request: '요청 보내기',
    should_not_empty: '필수 입력란입니다',
    should_be_number: '숫자로 입력해야 합니다',
    incorrect_value: '값이 틀립니다',
    incorrect_phone: '전화번호가 국제번호 형식인 +xx와 일치해야 합니다',
    success: '성공',
    error: '오류',
    invalid_phone_number: '유효하지 않은 전화번호',
    invalid_email: '유효하지 않은 이메일',
    submit: '제출',
    success_message: '정보를 성공적으로 제출했습니다',
    error_message: '정보 제출 중 오류가 발생했습니다. 다시 시도하거나 문제가 계속 되면 지원팀에 연락하세요.',
    no_schema: '이 기능은 설정되지 않았습니다',
    reset: '초기화',
    quick_entry_table_header_shipment_b2c: '배송',
    enter_quantities_segmented_control_b2c_by_shipment: '배송별',
    new_document_b2c_shipment: '배송',
    boxed_order: '박스형 주문',
    boxed_order_explanation_title: '박스형 주문',
    boxed_order_explanation: '주문에 박스를 1개 이상 추가하면 수량이 그 배수만큼이 됩니다. 따라서 입력한 박스의 #개에 걸쳐 입력한 수량이 중복되는 것입니다.',
    boxes: '박스',
    units_per_box: '박스 당 단위',
    regional: '지역',
    purchase_order_tooltip: '입력한 값이 유효하지 않습니다.',
    tournament_date_is_too_late: '날짜가 %{date} 전이어야 합니다',
    page_notes_error: '입력값이 유효하지 않습니다.',
    internet_connection_lost: '인터넷 연결 끊김',
    internet_connection_lost_builder_description: '<p>인터넷에 연결되지 않았습니다. 계속하려면 다시 연결하세요. 최근 수동 혹은 자동 저장으로 작업이 저장되었습니다.</p><p>데이터 손실에 대해 우려가 된다면 아래 버튼을 클릭하여 백업 데이터 파일을 다운로드 하고 <Link>support@elasticsuite.com</Link>으로 파일을 전달하여 주문을 복원하세요.</p>',
    internet_connection_lost_description: '현재 인터넷에 연결되어 있지 않습니다. 계속하려면 다시 연결하세요.',
    download_backup_data_file: '백업 데이터 파일 다운로드',
    submit_order_description: '주문을 제출합니다. 한 번 제출된 주문은 잠금 상태가 되어 편집할 수 없습니다. 주문이 클라우드에 저장되었는지 확인하세요.',
  }),
);
