define(
  ({
    copy: 'Копировать',
    paste: 'Вставить',
    copyToAll: 'Копировать везде',
    add_to_cart: 'Добавить в корзину',
    addToCart: 'Добавить в корзину',
    remove: 'Удалить',
    added: 'Добавлено',
    dropped: 'Удалено',
    units: 'Единицы измерения',
    total: 'Итого',
    atsWarning: 'Это количество не будет доступно к %{availableOn}.',
    inventory_warehouse: 'Складские запасы',
    notInCatalog: 'Невозможно добавить позицию в корзину',
    notInCatalogExplanation: 'К сожалению, добавление позиций из разных каталогов невозможно.',
    digital_market_notInCatalogExplanation: 'В корзине одновременно могут находиться только объекты одного бренда',
    removeFromCartSuggestion: 'Хотите <link>очистить корзину?</link>',
    digital_market_removeFromCartSuggestion: 'Чтобы добавить этот объект, можно <link>очистить корзину</link>',
    emptyCart: 'Очистить корзину',
    empty: 'Очистить',
    emptyConfirmation: 'Вы действительно хотите очистить корзину?',
    available_on: 'Доступно на дату',
    personalization: 'Персонализация',
    personalize: 'Персонализировать',
    placement: 'Размещение',
    content: 'Содержимое',
    color: 'Цвет',
    product_specification: 'Характеристики товара',
    type: 'Тип',
    logo: 'Логотип',
    text: 'Текст',
    text_and_logo: 'Текст, логотип',
    other: 'Другое',
    value_might_be_stale: 'Вследствие изменений в вашей корзине это значение может быть устаревшим. Сохраните свой заказ для пересчета.',
    more_details: 'Подробнее',
    availability: 'Доступность',
    no_scheduled_availability: 'Нет запланированной доступности',
    not_available_until: 'Недоступно до <date />',
    available_as_of: 'Доступно на дату <date />',
    units_bundled_singular_label: 'Объединено %{units} ед.',
    units_bundled_label: 'Объединено %{units} ед.',
    add_bundle_to_cart: 'Добавить комплект в корзину',
    bundles: 'Комплекты',
    bundled: 'В комплекте',
    product: 'Товар',
    sizes_and_quantities: 'Размер / Количество',
    units_singular_label: '%{units} ед.',
    units_label: '%{units} ед.',
    current_availability: 'Текущая доступность',
    future_availability: 'Доступность в будущем',
    size: 'Размер',
    sku: 'SKU',
    availability_date: 'Дата доступности',
    quantity: 'Кол-во',
    availability_subject_to_change: 'Доступность может меняться',
    yes: 'Да',
    no: 'Нет',
    copy_to_all_confirmation_message: 'Скопировать этот диапазон размеров на все стили ниже?',
  }),
);
