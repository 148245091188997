define(
  ({
    copy: 'Kopyala',
    paste: 'Yapıştır',
    copyToAll: 'Tümüne Kopyala',
    add_to_cart: 'Sepete Ekle',
    addToCart: 'Sepete Ekle',
    remove: 'Kaldır',
    added: 'Eklendi',
    dropped: 'Bırakıldı',
    units: 'Birimler',
    total: 'Toplam',
    atsWarning: 'Bu miktar %{availableOn} tarihine kadar müsait olmayacak.',
    inventory_warehouse: 'Envanter Deposu',
    notInCatalog: 'Öge Sepete Eklenemedi',
    notInCatalogExplanation: 'Üzgünüz, ama Kataloglar arasında öğe ekleme yapılamaz.',
    digital_market_notInCatalogExplanation: 'Sepetin aynı anda yalnızca bir markanın ürünlerini içerebilir',
    removeFromCartSuggestion: '<link>Sepetini boşaltmak</link> ister misin?',
    digital_market_removeFromCartSuggestion: 'Bu öğeyi eklemek için <link>sepetini boşaltabilirsin</link>',
    emptyCart: 'Sepeti Boşalt',
    empty: 'Boş',
    emptyConfirmation: 'Sepetini boşaltmak istediğinden emin misin?',
    available_on: 'Şunda Müsait:',
    personalization: 'Kişiseleştirme',
    personalize: 'Kişiselleştir',
    placement: 'Yerleştirme',
    content: 'İçerik',
    color: 'Renk',
    product_specification: 'Ürün Özellikleri',
    type: 'Tür',
    logo: 'Logo',
    text: 'Metin',
    text_and_logo: 'Metin, Logo',
    other: 'Diğer',
    value_might_be_stale: 'Sepetindeki değişikliklerden dolayı bu değerin tarihi geçmiş olabilir. Tekrar hesaplamak için siparişini kaydet.',
    more_details: 'Daha Fazla Ayrıntı',
    availability: 'Müsaitlik Durumu',
    no_scheduled_availability: 'Planlanan Müsait Olma Yok',
    not_available_until: '<date /> tarihine kadar müsait değil',
    available_as_of: '<date /> tarihi itibarı ile müsait',
    units_bundled_singular_label: '%{units} Birim Paketlendi',
    units_bundled_label: '%{units} Birim Paketlendi',
    add_bundle_to_cart: 'Sepete Paket Ekle',
    bundles: 'Paketler',
    bundled: 'Paketlendi',
    product: 'Ürün',
    sizes_and_quantities: 'Boyut / Miktar',
    units_singular_label: '%{units} Birim',
    units_label: '%{units} Birim',
    current_availability: 'Mevcut Müsaitlik Durumu',
    future_availability: 'Gelecek Müsaitlik Durumu',
    size: 'Boyut',
    sku: 'SKU',
    availability_date: 'Müsaitlik Tarihi',
    quantity: 'Mkt.',
    availability_subject_to_change: 'Müsaitlik durumu değişebilir',
    yes: 'Evet',
    no: 'Hayır',
    copy_to_all_confirmation_message: 'Bu beden aralığını aşağıdaki tüm tarzlara kopyalamak istediğinden emin misin?',
  }),
);
