define(
  ({
    app_name: 'Elastic',
    none: 'Nessuno',
    select: 'seleziona...',
    cancel: 'Annulla',
    drop_ship_instructions: 'Solo spedizione nazionale. Nessuna spedizione a APO o FPO.',
    product_data_sheet_tab_technology: 'Tecnologia',
    product_data_sheet_tab_overview: 'Descrizione',
    product_data_sheet_tab_details: 'Dettagli',
    cart_submit_order_dealer: 'Emetti ordine',
    cart_submit_order_rep: 'Emetti ordine',
    erp_order_number: 'Ordine ERP #',
    sap_order_number: "№ dell'ordine SAP",
    nda_explanation: 'Spiegazione NDA.',
    terms_and_conditions_description: 'Spunta la casella in basso per indicare di aver letto e accettato i <a href="###" target="_blank">Termini e le condizioni</a>',
    discounted_total: 'Totale scontato',
    upcharge: 'Sovrapprezzo',
    dont_show_me_this_again: 'Non mostrare più.',
    okay: 'Okay',
    arrival_date: 'Data di inizio spedizione',
    sso_message: 'Accesso esterno',
    alt_login_title: 'Accedi',
    prebook: 'Ordine Programmato',
    ats: 'ATS',
    alternate_images: 'Immagini alternative',
    choose: 'Scegli',
    new_version_available_modal: 'Nuova versione disponibile',
    new_version_available_message: "È disponibile una nuova versione dell'applicazione",
    new_version_reload: 'Carica nuova versione',
    new_version_snooze: 'Ricordamelo più tardi',
    new_version_save_and_reload: 'Salva il mio lavoro e ricarica',
    popup_may_be_suppressed_title: 'Pagina di pagamento aperta',
    popup_may_be_suppressed_description: 'È stata aperta una pagina per il pagamento con carta di credito. Se non la vedi, verifica la presenza di un popup bloccato nella barra degli indirizzi del browser e autorizzalo.',
    popup_blocked_title: 'Popup bloccato',
    popup_blocked_description: "La nuova finestra per inviare il pagamento era bloccata. Controlla le impostazioni e le estensioni del browser e riprova. Se il problema persiste, puoi salvare l'ordine e aprire Elastic in un altro browser. Elastic è perfettamente compatibile con le verisoni recenti di Google Chrome.",
    pay_by_credit_card: 'Paga con carta di credito',
    do_you_want_to_pay_by_credit_card: 'Vuoi pagare con carta di credito?',
    pay_by_credit_card_no: 'No',
    pay_by_credit_card_yes: 'Sì',
    cancel_order_submission_and_payment: "Annulla l'invio dell'ordine e il pagamento",
    product_reference_short: 'RIF.: %{reference}',
    product_reference_long: 'SKU anno precedente: %{reference}',
    variation_code: 'Codice di variazione',
    pending_orders: 'Ordini in sospeso',
    freight_discount_message: 'Gli articoli in questa spedizione si qualificano per lo sconto di spedizione %{sales_program}',
    partial_freight_discount_message: 'Scegli un metodo di spedizione per gli articoli in questa spedizione che non si qualificano per lo sconto di %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Gli articoli che non si qualificano per il %{sales_program} verranno spediti tramite il metodo predefinito',
    export_pdf: 'Esporta PDF',
    ots: 'Disponibile alla vendita',
    terms_and_conditions: 'Termini e condizioni',
    accept: 'Accetta',
    back_to_orders: 'Torna a Ordini',
    back_to_payments: 'Torna a Pagamenti',
    back_to_shipments: 'Torna a Spedizioni',
    required: 'Obbligatorio',
    send_request: 'invia richiesta',
    should_not_empty: "non dev'essere vuoto",
    should_be_number: 'deve essere un numero',
    incorrect_value: 'valore errato',
    incorrect_phone: 'il numero di telefono deve corrispondere al formato internazionale +xx',
    success: 'Completato',
    error: 'Errore',
    invalid_phone_number: 'Numero di telefono non valido',
    invalid_email: 'Email non valida',
    submit: 'Invia',
    success_message: 'Le tue informazioni sono state inviate',
    error_message: "Si è verificato un errore nell'invio delle informazioni. Se il problema persiste, riprova o contatta il servizio di assistenza.",
    no_schema: 'Questa funzione non è stata configurata',
    reset: 'Ripristina',
    quick_entry_table_header_shipment_b2c: 'Spedizione',
    enter_quantities_segmented_control_b2c_by_shipment: 'Con spedizione',
    new_document_b2c_shipment: 'Spedizione',
    boxed_order: 'Ordine inscatolato',
    boxed_order_explanation_title: 'Ordini inscatolati',
    boxed_order_explanation: "L'aggiunta di più di una scatola a un ordine agisce come un moltiplicatore di quantità. Le quantità inserite saranno duplicate in base al numero di scatole inserite.",
    boxes: 'Scatole',
    units_per_box: 'Unità per scatola',
    regional: 'Regionale',
    purchase_order_tooltip: 'Il valore inserito non è valido.',
    tournament_date_is_too_late: 'La data deve essere %{date} o precedente',
    page_notes_error: 'Il valore inserito non è valido.',
    internet_connection_lost: 'Connessione a Internet persa',
    internet_connection_lost_builder_description: "<p>Connessione Internet non disponibile. Riconnetti per continuare. Il tuo lavoro è stato salvato all'ultimo salvataggio automatico o manuale.</p><p>Se ti preoccupa la perdita di dati, clicca il pulsante qui sotto per scaricare un file dati di backup, e inoltra il file a <Link>support@elasticsuite.com</Link> per ripristinare il tuo ordine.</p>",
    internet_connection_lost_description: 'Al momento non sei connesso a Internet. Riconnetti per continuare.',
    download_backup_data_file: 'Scarica file dati di backup',
    submit_order_description: "Stai per inviare un ordine. Una volta inviato, esso verrà bloccato e non sarà più modificabile. Ricorda che l'ordine sarà salvato nel cloud.",
  }),
);
