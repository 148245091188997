define(
  ({
    copy: 'Kopi',
    paste: 'Kopier',
    copyToAll: 'Kopier til alle',
    add_to_cart: 'Legg til i handlekurven',
    addToCart: 'Legg til i handlekurven',
    remove: 'Fjern',
    added: 'Lagt til',
    dropped: 'Fjernet',
    units: 'Enheter',
    total: 'Totalt',
    atsWarning: 'Dette antallet vil ikke være tilgjengelig innen %{availableOn}.',
    inventory_warehouse: 'Beholdingslager',
    notInCatalog: 'Kan ikke legge varen i handlekurven',
    notInCatalogExplanation: 'Beklager, men å legge til artikler på tvers av kataloger er ikke mulig nå.',
    digital_market_notInCatalogExplanation: 'Handlekurven din kan bare inneholde varer fra ett merke om gangen',
    removeFromCartSuggestion: 'Vil du <link>tømme handlekurven din?</link>',
    digital_market_removeFromCartSuggestion: 'Du kan <link>tømme handlekurven</link> for å legge til denne varen',
    emptyCart: 'Tom handlekurv',
    empty: 'Tom',
    emptyConfirmation: 'Er du sikker på at du vil tømme handlekurven?',
    available_on: 'Tilgjengelig den',
    personalization: 'Personlig tilpasning',
    personalize: 'Personlig tilpasning',
    placement: 'Plassering',
    content: 'Innhold',
    color: 'Farge',
    product_specification: 'Produktspesifikasjon',
    type: 'Type',
    logo: 'Logo',
    text: 'Tekst',
    text_and_logo: 'Tekst, logo',
    other: 'Annet',
    value_might_be_stale: 'På grunn av endringer i handlekurven din, kan denne verdien være foreldet. Lagre bestillingen din for å beregne den på nytt.',
    more_details: 'Mer informasjon',
    availability: 'Tilgjengelighet',
    no_scheduled_availability: 'Ingen planlagt tilgjengelighet',
    not_available_until: 'Ikke tilgjengelig før <date />',
    available_as_of: 'Tilgjengelig fra <date />',
    units_bundled_singular_label: '%{enheter} enhet buntet',
    units_bundled_label: '%{enheter} enheter buntet',
    add_bundle_to_cart: 'Legg til bunten i handlekurven',
    bundles: 'Bunter',
    bundled: 'Buntet',
    product: 'Produkt',
    sizes_and_quantities: 'Størrelse/mengde',
    units_singular_label: '%{enheter} enhet',
    units_label: '%{enheter} enheter',
    current_availability: 'Gjeldende tilgjengelighet',
    future_availability: 'Fremtidig tilgjengelighet',
    size: 'Størrelse',
    sku: 'SKU',
    availability_date: 'Tilgjengelighetsdato',
    quantity: 'Ant.',
    availability_subject_to_change: 'Tilgjengeligheten kan endres',
    yes: 'Ja',
    no: 'Nei',
    copy_to_all_confirmation_message: 'Er du sikker på at du vil kopiere dette størrelsesområdet til alle stilene nedenfor?',
  }),
);
