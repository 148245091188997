define(
  ({
    copy: '复制',
    paste: '粘贴',
    copyToAll: '复制到所有',
    add_to_cart: '添加到购物车',
    addToCart: '添加到购物车',
    remove: '移除',
    added: '已添加',
    dropped: '已放弃',
    units: '单位',
    total: '总计',
    atsWarning: '此数量在%{availableOn}无货。',
    inventory_warehouse: '库存仓库',
    notInCatalog: '无法将项目添加到购物车',
    notInCatalogExplanation: '抱歉，无法跨目录添加项目。',
    digital_market_notInCatalogExplanation: '您的购物车一次只能放一个品牌的商品',
    removeFromCartSuggestion: '您想<link>清空购物车吗？</link>',
    digital_market_removeFromCartSuggestion: '您可以<link>清空购物车</link>再添加此商品',
    emptyCart: '清空购物车',
    empty: '清空',
    emptyConfirmation: '您确定要清空购物车吗？',
    available_on: '可用于',
    personalization: '个性化',
    personalize: '个性化',
    placement: '放置',
    content: '内容',
    color: '颜色',
    product_specification: '产品规格',
    type: '类型',
    logo: '标志',
    text: '文本',
    text_and_logo: '文本，标志',
    other: '其他',
    value_might_be_stale: '由于您购物车的更改，这个值可能已过时。保存您的订单以重新计算。',
    more_details: '更多详细信息',
    availability: '库存情况',
    no_scheduled_availability: '没有已安排库存情况',
    not_available_until: '<date />之前不可用',
    available_as_of: '可用期至<date />',
    units_bundled_singular_label: '%{units}个单元已绑定',
    units_bundled_label: '%{units}个单元已绑定',
    add_bundle_to_cart: '添加套装到购物车',
    bundles: '套装',
    bundled: '已绑定',
    product: '产品',
    sizes_and_quantities: '尺寸/数量',
    units_singular_label: '%{units}个单元',
    units_label: '%{units}个单元',
    current_availability: '当前库存情况',
    future_availability: '将来库存情况',
    size: '尺寸',
    sku: 'SKU',
    availability_date: '库存日期',
    quantity: '数量',
    availability_subject_to_change: '库存情况可能会变化',
    yes: '是',
    no: '否',
    copy_to_all_confirmation_message: '确定要将此尺寸区间复制到下方的全部款式吗？',
  }),
);
