define(
({
  "no_data_message": "Geen gegevens",
  "order_number": "Bestellings #",
  "elastic_order_number": "Elastic bestelling #",
  "line_number": "Regel #",
  "customer_name": "Klantnaam",
  "customer_number": "Klant #",
  "location_number": "Locatie #",
  "location_name": "Locatienaam",
  "product": "Product #",
  "style_name": "Productnaam",
  "code": "Kleurcode",
  "color": "Kleurnaam",
  "status": "Lijnstatus",
  "states": "Bestellingsstatus",
  "tracking": "Tracking #",
  "parcel_number": "Pakket #",
  "year": "Jaar",
  "season": "Seizoen",
  "order_date": "Datum aangemaakt",
  "cancel_date": "Annuleren voor",
  "invoice_date": "Factuurdatum",
  "invoice": "Factuur #",
  "invoice_url": "Factuur-URL",
  "download": "Downloaden",
  "view": "Weergeven",
  "ship_date": "Gewenste verzendingsdatum",
  "expected_ship_date": "Verwachte verzendingsdatum",
  "terms_name": "Naam voorwaarden",
  "po_number": "PO #",
  "size": "Formaat",
  "alt_name": "Formaat Lengte",
  "upc": "UPC",
  "units": "Hoev.",
  "amount": "Hoeveelheid",
  "net_price": "Met korting",
  "unit_price": "Prijs eenheid",
  "orders": "Bestellingen",
  "totals": "Totaal",
  "details": "Details",
  "open": "Open",
  "shipped": "Verzonden",
  "cancelled": "Geannuleerd",
  "canceled": "Geannuleerd",
  "released": "Vrijgegeven",
  "invoiced": "Gefactureerd",
  "picked": "Picked",
  "region": "Regio",
  "order_type": "Bestellingstype",
  "backorder_date": "Datum reservering",
  "backorder": "Reservering",
  "payment_terms": "Betalingstermijn",
  "ship_via": "Verzenden via",
  "line_discount": "Regelkorting",
  "rma_number": "RMA #",
  "cash_discount": "Cashkorting",
  "hold_for_confirmation": "Vastgehouden voor bevestiging",
  "credit_hold": "Credit Hold",
  "order_notes": "Bestellingsnotities",
  "product_series": "Productserie",
  "product_category": "Productcategorie",
  "product_sub_category": "Productsubcategorie",
  "quantity_confirmed": "Bevestigd",
  "quantity_backordered": "Gereserveerd",
  "quantity_cancelled": "Geannuleerd",
  "quantity_shipped": "Verzonden",
  "ivendix_order_id": "iVendix ID",
  "catalog": "Catalogus",
  "source": "Bron",
  "division": "Afdeling",
  "actual_ship_date": "Daadwerkelijke verzenddatum",
  "rep_name": "Naam vertegenwoordiger",
  "tracking_carrier": "Code carrier",
  "gross_amount": "Bruto aantal",
  "discount_percent": "Kortings-%"
})
);
