define(
  ({
    copy: 'Másolás',
    paste: 'Beillesztés',
    copyToAll: 'Másolás mindenhez',
    add_to_cart: 'Hozzáadás kosárhoz',
    addToCart: 'Hozzáadás kosárhoz',
    remove: 'Eltávolítás',
    added: 'Hozzáadva',
    dropped: 'Ejtve',
    units: 'Egységek',
    total: 'Összesen',
    atsWarning: 'Ez a mennyiség ekkor válik elérhetővé: %{availableOn}.',
    inventory_warehouse: 'Készletraktár',
    notInCatalog: 'Nem sikerült hozzáadni a tételt a kosárhoz',
    notInCatalogExplanation: 'Sajnos a katalógusok között nem lehet hozzáadni tételeket.',
    digital_market_notInCatalogExplanation: 'A kosár egyszerre csak egy márka termékeit tartalmazhatja',
    removeFromCartSuggestion: 'Szeretnéd <link>kiüríteni a kosarad?</link>',
    digital_market_removeFromCartSuggestion: 'A tétel hozzáadásához <link>kiürítheted a kosarad</link>',
    emptyCart: 'Kosár kiürítése',
    empty: 'Kiürítés',
    emptyConfirmation: 'Biztos kiüríted a kosarad?',
    available_on: 'Elérhető ekkor:',
    personalization: 'Személyreszabás',
    personalize: 'Személyreszab',
    placement: 'Elhelyezés',
    content: 'Tartalom',
    color: 'Szín',
    product_specification: 'Termékspecifikáció',
    type: 'Típus',
    logo: 'Logó',
    text: 'Szöveg',
    text_and_logo: 'Szöveg, logó',
    other: 'Egyéb',
    value_might_be_stale: 'A kosarad változtatása miatt ez az érték elavult lehet. Mentsd el a rendelésed az újraszámításért.',
    more_details: 'További részletek',
    availability: 'Elérhetőség',
    no_scheduled_availability: 'Nincs ütemezett elérhetőség',
    not_available_until: 'Nem elérhető el eddig: <date />',
    available_as_of: 'Elérhető ettől kezdve: <date />',
    units_bundled_singular_label: '%{units} egység kötegelve',
    units_bundled_label: '%{units} egység kötegelve',
    add_bundle_to_cart: 'Köteg hozzáadása kosárhoz',
    bundles: 'Kötegek',
    bundled: 'Kötegelve',
    product: 'Termék',
    sizes_and_quantities: 'Méret / Mennyiség',
    units_singular_label: '%{units} egység',
    units_label: '%{units} egység',
    current_availability: 'Jelenlegi elérhetőség',
    future_availability: 'Jövőbeli elérhetőség',
    size: 'Méret',
    sku: 'SKU',
    availability_date: 'Elérhetőség dátuma',
    quantity: 'Menny.',
    availability_subject_to_change: 'Az elérhetőség változhat',
    yes: 'Igen',
    no: 'Nem',
    copy_to_all_confirmation_message: 'Biztos át akarod másolni ezt a mérettartományt az összes alábbi stílusra?',
  }),
);
