define([
  'dojo/_base/lang',
  'scramble/env',
  'scramble/has',
  'scramble/widgets/findProductImage',
  'scramble/widgets/findAtsInfo',
  'dojo/i18n!scramble/widgets/nls/variationItem',
  'dojo/i18n!scramble/nls/common',
  'scramble-com/ui/scramble4-connect',
  'scramble-com/modules/paper-size',
  'react',
  'react-dom',
  'scramble/util/brandImages',
  'scramble/util/tenant',
  'scramble/getSupport',
], (
  lang,
  env,
  has,
  findProductImage,
  findAtsInfo,
  nls,
  nlsCommon,
  { default: scramble4Connect },
  { northAmericanDefault },
  React,
  ReactDOM,
  brandImages,
  { tenantId },
  getSupport,
) => {
  const defaultStyles = {
    brand: 'black',
    text: '#464646',
    background: '#212121',
    logoWidth: 'auto',
  };

  function transformLocale(locale) {
    return locale
      .replace(/-[a-z]+$/, (part) => part.toUpperCase())
      .replace('-', '_');
  }

  function getPrototype(obj) {
    return Object.getPrototypeOf ? Object.getPrototypeOf(obj) : obj.__proto__;
  }

  // 💁 properties in `b` will override properties in `a`
  function mergeNlsObjects(...objects) {
    return objects.reduce(
      (acc, cur) => Object.assign(acc, getPrototype(cur), cur),
      {},
    );
  }

  // this concept of base props is bad. Ultimately, we need to eliminate it.
  // Unfortunately, that means an exhaustive audit of renderReactComponent calls.
  // The required props will need to be looked up and added to the props of each affected component.
  function baseProps() {
    const doc = env.get('document') || env.get('searchDocument');
    const customer = doc && doc.get('customer');
    const user = env.get('user');
    const documentIsCatalog = doc && doc.get('isCatalog');

    return {
      customer,
      user,
      documentIsCatalog,
      catalogAtsMode:
        env.getFlag('atsLogic') === 'catalog'
          ? doc && doc.get('catalog').type
          : null,
    };
  }

  function createWrappedComponent(
    translations,
    component,
    {
      priceGroup = {}, currencyCode, className, defaultPaperSize,
    } = {},
  ) {
    const doc = env.get('document') || env.get('searchDocument');
    const customer = doc && doc.get('customer');
    return scramble4Connect({
      apolloClientOptions: env.getFlag('apolloClientOptions'),
      translations: mergeNlsObjects(nlsCommon, translations || {}),
      locale: transformLocale(dojo.locale),
      className,
      styles: {
        ...defaultStyles,
        ...env.getFlag('styles', {}),
        brand: defaultStyles.brand,
      },
      whiteboardAllowedLabels: env.getFlag('whiteboard.labels', [
        'product_name',
        'product_number',
        'color_name',
        'color_code',
        'price',
        'sizebreak',
        'allocated_quantity',
      ]),
      availabilityRestrictsValidity: env.getFlag(
        'availabilityRestrictsValidity',
      ),
      whiteboardTagLabels: env.getFlag('whiteboard.tagLabels', []),
      wholesalePriceGroup: customer
        ? customer.wholesale_price_group
        : priceGroup.wholesale,
      retailPriceGroup: customer
        ? customer.retail_price_group
        : priceGroup.retail,
      currencyCode: customer ? customer.currency_code : currencyCode,
      usesPageLevelDiscounts: env.getFlag('pageLevelDiscounts'),
      defaultImage: findProductImage.missing
        ? findProductImage.missing
        : (findProductImage.getMissing(), findProductImage.missing),
      usesAvailabilityWarnings: env.getFlag('availabilityWarnings'),
      enforceReleaseDate: env.getFlag(
        'enforceVariationAvailableOnForAvailability',
      ),
      externalBarcodeApi: env.getFlag('externalBarcodeApi', false),
      restrictShipToByDivision: env.getFlag('restrictShipToByDivision'),
      isTouchscreen: has('touch'),
      usesExternalPrices: env.getFlag('usesExternalPrices'),
      enableStrikethrough: env.getFlag('enableStrikethrough'),
      showExternalPriceWhenEqualToWholesalePrice: env.getFlag(
        'showExternalPriceWhenEqualToWholesalePrice',
      ),
      externalPriceTotalsShouldFallBackToWholesalePrice: env.getFlag(
        'externalPriceTotalsShouldFallBackToWholesalePrice',
      ),
      priceType: env.get('userSettings').get('priceType'),
      userDefaultOrderBuilderMode: env.userSettings.get('defaultOrderMode'),
      exportAvailability: env.getFlag('exportAvailability', false),
      importClientFile: env.getFlag('importClientFile', false),
      importOfflineOrder: env.getFlag('importOfflineOrder', false),
      // default automaticShipments entry { optionIsAvailable: false, defaultValue: false }
      // is added in the automatic-shipments scramble-com module
      automaticShipments: env.getFlag('automaticShipments', []),
      clientLogo: brandImages.imageUrl(env.get('appConfig').images, 'logo'),
      embellishmentsActionableForRepsOnly: env.getFlag(
        'embellishmentsActionableForRepsOnly',
        false,
      ),
      embellishmentStrategies: env.getEmbellishmentStrategies(),
      defaultOrderBuilderMode: env.getFlag('defaultBuilderMode'),
      defaultOrderBuilderModeForReps: env.getFlag('defaultBuilderModeForReps'),
      builderModeSpecifications: env.getFlag('builderModeSpecifications'),
      catalogCardLogos:
        lang.getObject(
          'images.catalogCardLogos',
          false,
          env.get('appConfig'),
        ) || {},
      clientName: env.getClientName(),
      tablet: has('tablet'),
      includeOTSColumn: env.getFlag('includeOTSColumn', false),
      atsLabelHigh: env.getFlag('atsHighLabel', '${max}+'),
      altNameSeparator: env.getFlag('altNameSeparator'),
      hideNotOrderable: env.getFlag('hideNotOrderable'),
      filterNotOrderable: env.getFlag('filterNotOrderable'),
      highlightSpecs: env.getFlag('highlightSpecs', []),
      usesExternalTaxCalculation: env.getFlag(
        'taxes.externalTaxCalculation',
        false,
      ),
      sendSupportRequest: getSupport,
      rep2repsharing: env.getFlag('rep2repsharing', false),
      onlyCustomerTags: env.getFlag('onlyCustomerTags'),
      exceptCustomerTags: env.getFlag('exceptCustomerTags'),
      hideWarehousesWithZeroInventory: env.getFlag(
        'hideWarehousesWithZeroInventory',
        false,
      ),
      usesProductLevelAts: env.getFlag('atsLogic') === 'product',
      productReferenceKeyPaths: env.getFlag('productReferenceKeyPaths', []),
      // there's some duplicate atsLabel logic here, although scramble-com
      // seems to ignore atsLabelMaxRep 🤷
      atsLabelMax: findAtsInfo.atsLabelMax(),
      defaultAtsLabelMax:
        env.get('user') && env.get('user').get('type') === 'rep'
          ? env.getFlag('atsLabelMaxRep')
          : env.getFlag('atsLabelMax'),
      forecastingAllowedUserTypes: env.getFlag('forecastingAllowedUserTypes'),
      productImportTemplates: env.getFlag('productImportTemplates'),
      onlyCatalogTags: env.getFlag('onlyCatalogTags'),
      exceptCatalogTags: env.getFlag('exceptCatalogTags'),
      secondaryCatalogTags: env.getFlag('secondaryCatalogTags'),
      newOrderDefaultCatalogTags: env.getFlag('newOrderDefaultCatalogTags'),
      tertiaryCatalogTags: env.getFlag('tertiaryCatalogTags'),
      forceCustomerSelection: env.getFlag('forceCustomerSelection'),
      usesCatalogTagFilters: env.getFlag('usesCatalogTagFilters'),
      newOrderSortCatalogsByName: env.getFlag('newOrderSortCatalogsByName'),
      catalogsRepresentBrands: env.getFlag('catalogsRepresentBrands'),
      quantityTableSizeSort: env.getFlag('quantityTableSizeSort'),
      skipNewOrderFormModal: env.getFlag('skipNewOrderFormModal', false),
      variationFormListLargeHeroImage: env.getFlag(
        'variationFormListLargeHeroImage',
      ),
      tenantId: tenantId(),
      accountHistoryAdministrator: env.getFlag('accountHistoryAdministrator'),
      truncateSizesLength: env.getFlag('truncateSizesLength'),
      defaultPaperSize: defaultPaperSize || northAmericanDefault,
      quantityFormLabel: env.getFlag('quantityFormLabel'),
      inventoryCheckMandatory: env.getFlag('inventoryCheckMandatory'),
      pageProductBoxes: env.getFlag('pageProductBoxes'),
      enterQuantities: env.getFlag('enterQuantities'),
      hideAllProductsButton: env.getFlag('hideAllProductsButton'),
      hideMoreDetailsButton: env.getFlag('hideMoreDetailsButton'),
      hideEditIconsInCartOverview: env.getFlag('hideEditIconsInCartOverview'),
      disableQuantityInput: env.getFlag('disableQuantityInput'),
      includeLocationNumberInSelect: env.getFlag(
        'includeLocationNumberInSelect',
      ),
      directoryMode: env.getFlag('directoryMode'),
      orderNameRegExp: env.getFlag('orderNameRegExp'),
      hideProductDataInUI: env.getFlag('hideProductDataInUI'),
      viewProducts: env.getFlag('viewProducts'),
      algoliaConfig: env.getFlag('algoliaConfig'),
      campaignsEnabled: env.getFlag('campaignsEnabled'),
      usesExternalOrderHistory: env.getFlag('usesExternalOrderHistory'),
      brandSummaryTag: env.get('brandSummaryTag'),
      searchResultsFilteredByAvailability: env.getFlag(
        'searchResultsFilteredByAvailability',
      ),
      ordersDashboardWidgetOrderNumberColumn: env.getFlag(
        'ordersDashboardWidgetOrderNumberColumn',
      ),
      includeCustomerNumberInSelect: env.getFlag(
        'includeCustomerNumberInSelect',
      ),
      hideAccountSettings: env.getFlag('hideAccountSettings'),
      hideProductSearch: env.getFlag('hideProductSearch'),
      thoughtspotSettings: env.getFlag('thoughtspotSettings'),
      includeThoughtspotMetricsForTheRep: env.getFlag('includeThoughtspotMetricsForTheRep'),
      includeThoughtspotMetricsForTheDealer: env.getFlag('includeThoughtspotMetricsForTheDealer'),
      shipmentsByAvailableStock: env.getFlag('shipmentsByAvailableStock'),
      showZeroValueDiscounts: env.getFlag('showZeroValueDiscounts'),
    })(component);
  }

  function renderReactComponent({ component, props, domNode }) {
    return ReactDOM.render(
      React.createElement(component, Object.assign(baseProps(), props)),
      domNode,
    );
  }

  function unmountReactComponentAtNode(domNode) {
    ReactDOM.unmountComponentAtNode(domNode);
  }

  function camelCasedPrograms(programs) {
    return programs.map((program) => ({
      ...program,
      ...(program.group
        ? {
          group: {
            ...program.group,
            id: program.group._id || program.group.id,
          },
        }
        : {}),
    }));
  }

  function camelCasedOrder(usesPageLevelDiscounts, order) {
    return {
      ...order,
      programStatus: usesPageLevelDiscounts && Array.isArray(order.programStatus)
        ? order.programStatus.map((ps) => ({
          achievedPrograms: ps.achieved_programs,
          programs: camelCasedPrograms(ps.programs),
        }))
        : {
          achievedPrograms: order.programStatus.achieved_programs,
          programs: camelCasedPrograms(order.programStatus.programs),
        },
    };
  }

  return {
    createWrappedComponent,
    mergeNlsObjects,
    unmountReactComponentAtNode,
    renderReactComponent,
    camelCasedOrder,
  };
});
