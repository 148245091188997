define(
  ({
    copy: 'Copiar',
    paste: 'Pegar',
    copyToAll: 'Copiar a todo',
    add_to_cart: 'Añadir al carrito',
    addToCart: 'Agregar al carro',
    remove: 'Eliminar',
    added: 'Añadido',
    dropped: 'Entregado',
    units: 'Unidades',
    total: 'Total',
    atsWarning: 'Esta cantidad no estará disponible hasta el %{availableOn}.',
    inventory_warehouse: 'Almacén de inventario',
    notInCatalog: 'No es posible añadir el artículo al carrito',
    notInCatalogExplanation: 'Lo sentimos, pero no está disponible añadir artículos entre catálogos.',
    digital_market_notInCatalogExplanation: 'Tu carrito solo puede contener objetos de una marca al mismo tiempo',
    removeFromCartSuggestion: '¿Te gustaría <link>vaciar tu carrito?</link>',
    digital_market_removeFromCartSuggestion: 'Puedes <link>vaciar el carrito</link> para añadir este objeto',
    emptyCart: 'Vaciar carrito',
    empty: 'Vacío',
    emptyConfirmation: '¿Seguro que quieres vaciar tu carrito?',
    available_on: 'Disponible en',
    personalization: 'Personalización',
    personalize: 'Personalizar',
    placement: 'Emplazamiento',
    content: 'Contenido',
    color: 'Color',
    product_specification: 'Especificación de producto',
    type: 'Tipo',
    logo: 'Logo',
    text: 'Texto',
    text_and_logo: 'Texto, logo',
    other: 'Otro',
    value_might_be_stale: 'Debido a los cambios en tu pedido, este valor puede estar fuera de fecha. Guarda tu pedido para recalcular.',
    more_details: 'Más detalles',
    availability: 'Disponibilidad',
    no_scheduled_availability: 'No hay disponibilidad programada',
    not_available_until: 'No disponible hasta <date />',
    available_as_of: 'Disponible el <date />',
    units_bundled_singular_label: '%{units} unidad en lote',
    units_bundled_label: '%{units} unidades en lote',
    add_bundle_to_cart: 'Añadir lote al carrito',
    bundles: 'Lotes',
    bundled: 'En lote',
    product: 'Producto',
    sizes_and_quantities: 'Tamaño / Cantidad',
    units_singular_label: '%{units} unidad',
    units_label: '%{units} unidades',
    current_availability: 'Disponibilidad actual',
    future_availability: 'Disponibilidad futura',
    size: 'Tamaño',
    sku: 'SKU',
    availability_date: 'Fecha de disponibilidad',
    quantity: 'Cant.',
    availability_subject_to_change: 'La disponibilidad está sujeta a cambios',
    yes: 'Sí',
    no: 'No',
    copy_to_all_confirmation_message: '¿Seguro que quieres copiar este rango de tamaños a todos los estilos siguientes?',
  }),
);
