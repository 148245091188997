define(
  ({
    copy: 'Kopiera',
    paste: 'Klistra in',
    copyToAll: 'Kopiera till alla',
    add_to_cart: 'Lägg till i kundvagn',
    addToCart: 'Lägg till i kundvagn',
    remove: 'Ta bort',
    added: 'Tillagt',
    dropped: 'Tillagd',
    units: 'Enheter',
    total: 'Totalt',
    atsWarning: 'Detta antal finns inte förrän %{availableOn}.',
    inventory_warehouse: 'Lager',
    notInCatalog: 'Kunde inte lägga till artikel i kundvagnen',
    notInCatalogExplanation: 'Tyvärr går det inte att lägga till artiklar mellan kundvagnar.',
    digital_market_notInCatalogExplanation: 'Din kundvagn kan endast innehålla produkter från ett märke åt gången',
    removeFromCartSuggestion: 'Vill du <link>tömma din kundvagn?</link>',
    digital_market_removeFromCartSuggestion: 'Du kan <link>tömma din kundvagn</link> för att lägga till denna produkt',
    emptyCart: 'Tom vagn',
    empty: 'Tom',
    emptyConfirmation: 'Är det säkert att du vill tömma din kundvagn?',
    available_on: 'Finns fr o m',
    personalization: 'Personlig anpassning',
    personalize: 'Anpassa personligt',
    placement: 'Placering',
    content: 'Innehåll',
    color: 'Färg',
    product_specification: 'Produktspecifikation',
    type: 'Typ',
    logo: 'Logotyp',
    text: 'Text',
    text_and_logo: 'Text, logotyp',
    other: 'Annan',
    value_might_be_stale: 'På grund av ändringar i din kundvagn är det möjligt att det här värdet är inaktuellt. Spara din beställning för att räkna om igen.',
    more_details: 'Fler detaljuppgifter',
    availability: 'Tillgänglighet',
    no_scheduled_availability: 'Ingen schemalagd tillgänglighet',
    not_available_until: 'Ej tillgänglig förrän <date />',
    available_as_of: 'I lager fr o m <date />',
    units_bundled_singular_label: '% {units} paketenhet',
    units_bundled_label: '% {units} paketenheter',
    add_bundle_to_cart: 'Lägg till paket i kundvagn',
    bundles: 'Paket',
    bundled: 'Paket',
    product: 'Produkt',
    sizes_and_quantities: 'Storlek / antal',
    units_singular_label: '% {units} enhet',
    units_label: '% {units} enheter',
    current_availability: 'Aktuell lagerstatus',
    future_availability: 'Framtida lagerstatus',
    size: 'Storlek',
    sku: 'SKU',
    availability_date: 'Lagerdatum',
    quantity: 'Antal',
    availability_subject_to_change: 'Lagerstatus kan komma att ändras',
    yes: 'Ja',
    no: 'Nej',
    copy_to_all_confirmation_message: 'Är det säkert att du vill kopiera dessa storlekar till alla stilar nedan?',
  }),
);
