define(
  ({
    copy: 'Copiar',
    paste: 'Colar',
    copyToAll: 'Copiar para tudo',
    add_to_cart: 'Adicionar ao carrinho',
    addToCart: 'Adicionar ao carrinho',
    remove: 'Remover',
    added: 'ADicionado',
    dropped: 'Abandonado',
    units: 'Unidades',
    total: 'Total',
    atsWarning: 'Esta quantidade não estará disponível em %{availableOn}.',
    inventory_warehouse: 'Inventário no armazém',
    notInCatalog: 'Impossível adicionar item ao carrinho',
    notInCatalogExplanation: 'Lamentamos, mas adicionar itens de diferentes catálogos não é possível.',
    digital_market_notInCatalogExplanation: 'O seu carrinho só pode ter itens de uma marca de cada vez',
    removeFromCartSuggestion: 'Pretende <link>esvaziar o seu carrinho?</link>',
    digital_market_removeFromCartSuggestion: 'Pode <link>esvaziar o seu carrinho</link> para adicionar este item',
    emptyCart: 'Esvaziar carrinho',
    empty: 'Esvaziar',
    emptyConfirmation: 'Tem a certeza de que pretende esvaziar o seu carrinho?',
    available_on: 'Disponível a',
    personalization: 'Personalização',
    personalize: 'Personalizar',
    placement: 'Colocação',
    content: 'Conteúdo',
    color: 'Cor',
    product_specification: 'Detalhes do produto',
    type: 'Tipo',
    logo: 'Logótipo',
    text: 'Texto',
    text_and_logo: 'Texto, logótipo',
    other: 'Outro',
    value_might_be_stale: 'Por motivos de alteração ao seu carrinho, este valor pode não estar atualizado. Guarde a sua encomenda para voltar a calcular.',
    more_details: 'Descrição',
    availability: 'Disponibilidade',
    no_scheduled_availability: 'Não há uma previsão da disponibilidade',
    not_available_until: 'Indisponível até <date />',
    available_as_of: 'Disponível a partir de <date />',
    units_bundled_singular_label: '%{units} unidade agrupada',
    units_bundled_label: '%{units} unidades agrupadas',
    add_bundle_to_cart: 'Adicionar pacote ao carrinho',
    bundles: 'Pacotes',
    bundled: 'Agrupado',
    product: 'Produto',
    sizes_and_quantities: 'Tamanho / Quantidade',
    units_singular_label: '%{units} unidade',
    units_label: '%{units} unidades',
    current_availability: 'Disponibilidade atual',
    future_availability: 'Disponibilidade futura',
    size: 'Tamanho',
    sku: 'SKU',
    availability_date: 'Data da disponibilidade',
    quantity: 'Qntd.',
    availability_subject_to_change: 'Disponibilidade sujeita a alterações',
    yes: 'Sim',
    no: 'Não',
    copy_to_all_confirmation_message: 'Tem a certeza de que pretende copiar esta gama de tamanhos para todos os estilos abaixo?',
  }),
);
