define(["scramble/env"], function(env) {
  return function() {
    window.open(
      [
        env.getFlag('supportUrl'),
        env.getFlag('supportEmail'),
        'http://en.support.elasticsuite.com/en/support/home'
      ]
      .filter(url => url)[0]
    );
  };
});
