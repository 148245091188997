/* eslint-disable no-param-reassign */
define([
  'dojo/text!./config.jsonc',
  'scramble/util/main',
  'scramble/util/flatmap-polyfill',
  'scramble/versionChecker', // this sets itself up by virtue of being imported
  'scramble/offlineNotificationMonitor',
  'scramble/resources/stylesheets/scramble.less',
  '@elastic-internal/lucide-static/font/lucide.css',
], (config, { bootstrap }) => {
  bootstrap(config.default || config);
});
