define(
  ({
    app_name: 'Elastic',
    none: 'なし',
    select: '選択...',
    cancel: 'キャンセル',
    drop_ship_instructions: '国内発送のみ。APOまたはFPOには配送できません。',
    product_data_sheet_tab_technology: 'テクノロジー',
    product_data_sheet_tab_overview: '概要',
    product_data_sheet_tab_details: '詳細',
    cart_submit_order_dealer: '注文する',
    cart_submit_order_rep: '注文する',
    erp_order_number: 'ERP注文#',
    sap_order_number: 'SAP注文番号',
    nda_explanation: '秘密保持契約の説明',
    terms_and_conditions_description: '下記にチェックを入れて、<a href="###" target="_blank">規約と条件</a>を読んだ上で同意することを表明してください',
    discounted_total: '割引適用後の合計',
    upcharge: '追加料金',
    dont_show_me_this_again: '今後表示しない。',
    okay: 'OK',
    arrival_date: '出荷予定日',
    sso_message: '外部ログイン',
    alt_login_title: 'ログイン',
    prebook: '事前予約',
    ats: '発送可能',
    alternate_images: '代替画像',
    choose: '選択する',
    new_version_available_modal: '新しいバージョンが利用できます',
    new_version_available_message: 'アプリケーションの新しいバージョンが利用できます',
    new_version_reload: '新しいバージョンを読み込む',
    new_version_snooze: '後で通知する',
    new_version_save_and_reload: '作業内容を保存して再読み込み',
    popup_may_be_suppressed_title: 'お支払いページをご利用いただけます',
    popup_may_be_suppressed_description: 'クレジットカードお支払いページがご利用いただけるようになりました。ページが表示されていない場合は、ブラウザのアドレスバーを確認して、ポップアップのブロックを解除してください。',
    popup_blocked_title: 'ポップアップがブロックされています',
    popup_blocked_description: 'お支払いを送信するための新しいウィンドウがブロックされました。ブラウザ設定と拡張機能を確認してから再度お試しください。問題が解決しない場合は、ご注文を保存してから他のブラウザでElasticを開いてください。Elasticは最新版のGoogle Chromeでのご利用が最適です。',
    pay_by_credit_card: 'クレジットカードで支払う',
    do_you_want_to_pay_by_credit_card: 'クレジットカードで支払いますか？',
    pay_by_credit_card_no: 'いいえ',
    pay_by_credit_card_yes: 'はい',
    cancel_order_submission_and_payment: '注文の送信と支払いをキャンセル',
    product_reference_short: '参照: %{reference}',
    product_reference_long: '前年度のSKU: %{reference}',
    variation_code: '認証コード',
    pending_orders: '保留中の注文',
    freight_discount_message: 'この発送に含まれるアイテムは、送料%{sales_program}割引の対象です',
    partial_freight_discount_message: 'この発送に含まれるアイテムで、送料%{sales_program}割引の対象外のアイテムに適用する発送方法を選択してください',
    non_qualifying_items_ship_by_default_method: '%{sales_program}の対象外のアイテムは、デフォルトの方法で発送されます',
    export_pdf: 'PDF形式でエクスポート',
    ots: '販売可能',
    terms_and_conditions: '規約および条件',
    accept: '同意',
    back_to_orders: '注文に戻る',
    back_to_payments: 'お支払いに戻る',
    back_to_shipments: '発送に戻る',
    required: '必須',
    send_request: 'リクエストを送信',
    should_not_empty: '空欄にはできません',
    should_be_number: '数字を入力してください',
    incorrect_value: '無効な値',
    incorrect_phone: '電話番号は国番号(+xx)を含む国際電話番号と一致しなければなりません',
    success: '成功',
    error: 'エラー',
    invalid_phone_number: '電話番号が無効です',
    invalid_email: 'メールアドレスが無効です',
    submit: '送信',
    success_message: '情報が正常に送信されました',
    error_message: '情報の送信時にエラーが発生しました。再度お試しいただくか、問題が改善されない場合は、サポートまでご連絡ください。',
    no_schema: 'この機能は設定されていません',
    reset: 'リセット',
    quick_entry_table_header_shipment_b2c: '発送',
    enter_quantities_segmented_control_b2c_by_shipment: '発送別',
    new_document_b2c_shipment: '発送',
    boxed_order: 'セット注文',
    boxed_order_explanation_title: 'セット注文',
    boxed_order_explanation: '注文に2セット以上追加すると、セット数の分だけ数量が倍になります。入力された数量は、入力されたセット数の分だけ複製されます。',
    boxes: 'セット',
    units_per_box: '1セットあたりの個数',
    regional: '地域限定',
    purchase_order_tooltip: '入力された値が有効ではありません。',
    tournament_date_is_too_late: '%{date}またはそれ以前の日付にしてください',
    page_notes_error: '入力された値が有効ではありません。',
    internet_connection_lost: 'インターネット接続が切断されました',
    internet_connection_lost_builder_description: '<p>インターネットに接続されていません。再接続してから続行してください。作業内容は最後に実行された手動保存または自動保存によって保存されています。</p><p>データの損失が懸念される場合は、下のボタンをクリックしてバックアップデータファイルをダウンロードし、<Link>support@elasticsuite.com</Link>まで転送して注文を復元させてください。 </p>',
    internet_connection_lost_description: 'インターネットに接続されていません。再接続してから続行してください。',
    download_backup_data_file: 'バックアップデータファイルをダウンロード',
    submit_order_description: 'まもなく注文を送信します。完了すると、注文はロックされ、編集できなくなります。注文はクラウドに保存されます。',
  }),
);
