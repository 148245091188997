define(
  ({
    copy: 'Kopírovat',
    paste: 'Vložit',
    copyToAll: 'Kopie všem',
    add_to_cart: 'Přidat do košíku',
    addToCart: 'Přidat do košíku',
    remove: 'Odebrat',
    added: 'Přidáno',
    dropped: 'Vynecháno',
    units: 'Jednotky',
    total: 'Celkem',
    atsWarning: 'Toto množství nebude k dispozici do %{availableOn}.',
    inventory_warehouse: 'Skladové zásoby',
    notInCatalog: 'Nelze přidat položku do košíku',
    notInCatalogExplanation: 'Litujeme, ale přidávání položek do katalogů není k dispozici.',
    digital_market_notInCatalogExplanation: 'Váš košík může v danou dobu obsahovat pouze položky od jedné značky',
    removeFromCartSuggestion: 'Chcete <link> vyprázdnit svůj košík?</link>',
    digital_market_removeFromCartSuggestion: 'Pro přidání této položky můžete <link>vyprázdnit košík</link>',
    emptyCart: 'Prázdný košík',
    empty: 'Prázdné',
    emptyConfirmation: 'Opravdu chcete vyprázdnit svůj košík?',
    available_on: 'K dispozici dne',
    personalization: 'Přizpůsobení',
    personalize: 'Přizpůsobit',
    placement: 'Umístění',
    content: 'Obsah',
    color: 'Barva',
    product_specification: 'Specifikace produktu',
    type: 'Typ',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, Logo',
    other: 'Jiné',
    value_might_be_stale: 'Z důvodu změn ve vašem košíku může být tato hodnota zastaralá. Uložit objednávku k přepočítání.',
    more_details: 'Další podrobnosti',
    availability: 'Dostupnost',
    no_scheduled_availability: 'Žádná naplánovaná dostupnost',
    not_available_until: 'Není k dispozici do <date />',
    available_as_of: 'K dispozici od <date />',
    units_bundled_singular_label: '%{units} jednotky v sadě',
    units_bundled_label: '%{units} jednotek v sadě',
    add_bundle_to_cart: 'Přidat sadu do košíku',
    bundles: 'Sady',
    bundled: 'V sadě',
    product: 'Produkt',
    sizes_and_quantities: 'Velikost / množství',
    units_singular_label: '%{units} jednotka',
    units_label: '%{units} jednotky',
    current_availability: 'Aktuální dostupnost',
    future_availability: 'Budoucí dostupnost',
    size: 'Velikost',
    sku: 'SKU',
    availability_date: 'Datum dostupnosti',
    quantity: 'Množství.',
    availability_subject_to_change: 'Dostupnost se může změnit',
    yes: 'Ano',
    no: 'Ne',
    copy_to_all_confirmation_message: 'Opravdu chcete tento rozsah velikostí zkopírovat do všech níže uvedených stylů?',
  }),
);
