/* eslint-disable no-param-reassign */
define([
  'dojo/dom-geometry',
  'scramble/env',
  'scramble/controllers/ConfigController',
  'clientIndex',
  'dojox/app/main',
  'scramble-com/store',
  'scramble-com/actions',
  'scramble-com/modules/user-session-tracer',
  'scramble/util/fetch', // polyfill
], (
  domGeo,
  env,
  configController,
  { default: clients },
  application,
  store,
  actions,
  { default: userSessionTracer },
) => {
  const appendFavicon = () => {
    const link = document.querySelector("link[rel*='icon']")
      || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    const faviconUrl = (window.clientBundle
        && window.clientBundle.images
        && window.clientBundle.images.image_favicon_ico)
      || require.toUrl('client/images/favicon.ico');

    link.href = env.getFlag('favicon', faviconUrl);
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  const appendVantivScripts = () => {
    // the payment setting it tied to a Vantiv integration
    // currently it is used by Patagonia
    if (!env.getFlag('payments')) return;
    const head = document.getElementsByTagName('head')[0];

    const jQuery = document.createElement('script');
    jQuery.src = 'https://code.jquery.com/jquery-3.3.1.min.js';
    jQuery.async = true;
    jQuery.onload = () => {
      const eprotectIFrameClient = document.createElement('script');
      eprotectIFrameClient.src = 'https://request.eprotect.vantivcnp.com/eProtect/js/eProtect-iframe-client.min.js';
      eprotectIFrameClient.async = true;
      head.appendChild(eprotectIFrameClient);
    };
    head.appendChild(jQuery);
  };

  const appendWalkMe = () => {
    const guid = env.getFlag('walkMeGUID', null);
    if (guid === null) return;
    const walkme = document.createElement('script');
    walkme.type = 'text/javascript';
    walkme.async = true;
    walkme.src = `https://cdn.walkme.com/users/${guid}/${
      env.isProduction() ? '' : 'test/'
    }walkme_${guid}_https.js`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(walkme, s);
    window._walkmeConfig = { smartLoad: true };
  };

  const rudderstackWriteKeyForEnvironment = {
    production: '1wbPi8GPfdZq4Yg17VIwKMDwzWx',
    beta: '1wbPgP3IsWmyfg9kIPTIWMNvw7Q',
    staging: '1wbPeJ3lXENZ1Mbvd7rv0jUmxGR',
    development: '1vJpaezOHHfNsC7UGBV2WpaV5JN',
  };

  const initializeUserSessionTracer = (config) => {
    userSessionTracer().initialize({
      sinks: [
        {
          type: 'SINK_TYPE_GOOGLE_ANALYTICS_GTAG',
          options: {
            gaMeasurementId: 'UA-114434126-2',
            dataLayerName: 'elasticGtagDataLayer',
          },
        },
        {
          type: 'SINK_TYPE_RUDDERSTACK',
          options: {
            writeKey:
              rudderstackWriteKeyForEnvironment[env.usefulEnvironment()],
            dataPlaneUrl: 'https://emeraldx-dataplane.rudderstack.com',
          },
        },
      ],
    });
  };

  const setBoxModel = () => {
    domGeo.boxModel = 'border-box';
  };

  const getJson = (url) => fetch(url)
    .then((res) => res.json())
    .catch((err) => console.error(err));

  const fetchExternalClientConfig = () => getJson(`${env.get('apiTarget')}config.json?type=scramble`);

  const fetchInternalClientConfig = (client) => getJson(
    require.toUrl(
      `${
        window.location.pathname.match(/^\/admin\//) ? '/iab/' : ''
      }clients/${client}/config.json`,
    ),
  );

  const resolvedAppConfig = (defaultAppConfig) => ({ internalClientConfig, externalClientConfig }) => configController(
    defaultAppConfig,
    internalClientConfig,
    externalClientConfig,
  );

  const fetchClientConfigs = () => Promise.resolve()
    .then(fetchExternalClientConfig)
    .then(({ client, config: externalClientConfig }) => fetchInternalClientConfig(client).then((internalClientConfig) => ({
      client,
      externalClientConfig,
      internalClientConfig,
    })));

  const setClient = (client) => {
    window.shortClientKey = client;

    env.set({
      client,
    });
  };

  const setAppConfig = (config) => {
    env.set('appConfig', config);
    store.getInstance().dispatch(actions.setApiUrl(env.get('apiTarget')));
  };

  const initializeApp = (config) => {
    const app = application(config, document.body);
    // for testing (see: skillet/spec/support/capybara_helpers.rb)
    window.elasticScramble = window.elasticScramble || app;
  };

  const setClientBundle = (client) => (
    (clients && clients[client] && clients[client]())
      || Promise.resolve(null)
  ).then((clientBundle) => {
    window.clientBundle = clientBundle;
  });

  const bootstrap = (defaultAppConfig) => Promise.resolve()
    .then(fetchClientConfigs)
    .then(({ client, ...clientConfigs }) => {
      setBoxModel();
      const config = resolvedAppConfig(defaultAppConfig)(clientConfigs);
      return setClientBundle(client).then(() => {
        setAppConfig(config);
        setClient(client);

        appendFavicon();
        appendWalkMe();
        appendVantivScripts();
        initializeUserSessionTracer(config);

        initializeApp(config);
      });
    });

  return {
    appendFavicon,
    appendWalkMe,
    appendVantivScripts,
    bootstrap,
  };
});
