define(
  ({
    app_name: 'Elastic',
    none: 'Žiadne',
    select: 'vyberte...',
    cancel: 'Zrušiť',
    drop_ship_instructions: 'Iba domáce doručenie. Bez doručenia do APO alebo FPO.',
    product_data_sheet_tab_technology: 'Technológia',
    product_data_sheet_tab_overview: 'Súhrn',
    product_data_sheet_tab_details: 'Podrobnosti',
    cart_submit_order_dealer: 'Zadať objednávku',
    cart_submit_order_rep: 'Zadať objednávku',
    erp_order_number: 'Č objednávky ERP',
    sap_order_number: 'Č. objednávky SAP',
    nda_explanation: 'Vysvetlenie zmluvy o mlčanlivosti (NDA).',
    terms_and_conditions_description: 'Nižšie začiarknutím označte, že máte prečítané <a href="###" target="_blank">Zmluvné podmienky</a> a súhlasíte s nimi',
    discounted_total: 'Spolu po zľave',
    upcharge: 'Doplatok',
    dont_show_me_this_again: 'Toto už nezobrazovať.',
    okay: 'OK',
    arrival_date: 'Dátum začiatku doručenia',
    sso_message: 'Externé prihlásenie',
    alt_login_title: 'Prihlásenie',
    prebook: 'Vopred rezervovať',
    ats: 'ATS',
    alternate_images: 'Alternatívne obrázky',
    choose: 'Vybrať',
    new_version_available_modal: 'Je dostupná nová verzia',
    new_version_available_message: 'Je dostupná nová verzia aplikácie',
    new_version_reload: 'Načítať novú verziu',
    new_version_snooze: 'Pripomenúť mi neskôr',
    new_version_save_and_reload: 'Uložiť moju prácu a načítať znova',
    popup_may_be_suppressed_title: 'Stránka platby otvorená',
    popup_may_be_suppressed_description: 'Stránka platby kreditnou kartou bola otvorená. Ak ju nevidíte, skontrolujte, či sa vám v prehliadači v poli s adresou nezobrazuje zablokované vyskakovacie okno, a povoľte ho.',
    popup_blocked_title: 'Vyskakovacie okno zablokované',
    popup_blocked_description: 'Nové okno na odoslanie platby bolo zablokované. Skontrolujte nastavenia a rozšírenia prehliadača a skúste to znova. Ak bude problém pretrvávať, môžete si objednávku uložiť a Elastic otvoriť v inom prehliadači. Aplikácia Elastic je najlepšie podporovaná v nedávnych verziách prehliadača Google Chrome.',
    pay_by_credit_card: 'Zaplatiť kreditnou kartou',
    do_you_want_to_pay_by_credit_card: 'Chcete zaplatiť kreditnou kartou?',
    pay_by_credit_card_no: 'Nie',
    pay_by_credit_card_yes: 'Áno',
    cancel_order_submission_and_payment: 'Zrušiť odoslanie objednávky a platbu',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'SKU z minulého roka: %{reference}',
    variation_code: 'Kód variácie',
    pending_orders: 'Čakajúce objednávky',
    freight_discount_message: 'Položky v tomto doručení spĺňajú podmienky na získanie %{sales_program} zľavy na doručenie',
    partial_freight_discount_message: 'Vyberte spôsob doručenia pre položky v tejto zásielke, ktoré nespĺňajú podmienky na %{sales_program} zľavu na doručenie',
    non_qualifying_items_ship_by_default_method: 'Položky, ktoré nesplnili podmienky na získanie %{sales_program} zľavy na doručenie budú odoslané predvoleným spôsobom',
    export_pdf: 'Exportovať PDF',
    ots: 'OTS',
    terms_and_conditions: 'Zmluvné podmienky',
    accept: 'Prijať',
    back_to_orders: 'Späť na objednávky',
    back_to_payments: 'Späť na platby',
    back_to_shipments: 'Späť na doručenia',
    required: 'Povinné',
    send_request: 'poslať žiadosť',
    should_not_empty: 'nemalo by byť prázdne',
    should_be_number: 'malo by ísť o číslo',
    incorrect_value: 'nesprávna hodnota',
    incorrect_phone: 'telefónne číslo by malo zodpovedať medzinárodnému formátu +xx',
    success: 'Podarilo sa',
    error: 'Chyba',
    invalid_phone_number: 'Neplatné telefónne číslo',
    invalid_email: 'Neplatný e-mail',
    submit: 'Odoslať',
    success_message: 'Vaše informácie boli úspešne odoslané',
    error_message: 'Pri odosielaní vašich informácií sa vyskytla chyba. Skúste to znova a ak bude problém pretrvávať, kontaktujte podporu.',
    no_schema: 'Táto funkcia nebola nakonfigurovaná',
    reset: 'Resetovať',
    quick_entry_table_header_shipment_b2c: 'Doručenie',
    enter_quantities_segmented_control_b2c_by_shipment: 'Podľa doručenia',
    new_document_b2c_shipment: 'Doručenie',
    boxed_order: 'Zabalená objednávka',
    boxed_order_explanation_title: 'Zabalené objednávky',
    boxed_order_explanation: 'Pridanie viac než jednej škatule do objednávky slúži ako násobiteľ množstva. Zadané množstvá budú duplikované v rámci # zadaných škatúľ.',
    boxes: 'Škatule',
    units_per_box: 'Jednotky na škatulu',
    regional: 'Regionálne',
    purchase_order_tooltip: 'Zadaná hodnota je neplatná.',
    tournament_date_is_too_late: 'Dátum musí byť %{date} alebo skôr',
    page_notes_error: 'Zadaná hodnota je neplatná.',
    internet_connection_lost: 'Internetové pripojenie stratené',
    internet_connection_lost_builder_description: '<p>Nemáte pripojenie na internet. Ak chcete pokračovať, znova sa pripojte. Vaša práca sa uložila pri poslednom manuálnom alebo automatickom uložení.</p><p>Ak sa obávate straty údajov, kliknite na tlačidlo nižšie a stiahnite si tak záložný súbor s údajmi a prepošlite ho na <Link>support@elasticsuite.com</Link>, aby ste svoju objednávku obnovili.</p>',
    internet_connection_lost_description: 'Momentálne nemáte internetové pripojenie. Ak chcete pokračovať, znova sa pripojte.',
    download_backup_data_file: 'Stiahnuť súbor so záložnými údajmi',
    submit_order_description: 'Vaša objednávka sa chystá na odoslanie. Keď bude odoslaná, uzamkne sa a už ju nebude možné upraviť. Upozorňujeme, že objednávka bude uložená do cloudu.',
  }),
);
