define(
({
  "no_data_message": "Nessun dato",
  "order_number": "N° ordine",
  "elastic_order_number": "N° d'ordine di Elastic",
  "line_number": "Riga nº",
  "customer_name": "Nome cliente",
  "customer_number": "N° cliente",
  "location_number": "№ di localizzazione",
  "location_name": "Nome posizione",
  "product": "N° prodotto",
  "style_name": "Nome del prodotto",
  "code": "Codice colore",
  "color": "Nome colore",
  "status": "Stato linea",
  "states": "Stato dell'ordine",
  "tracking": "№ di seguimento",
  "parcel_number": "Collo n.",
  "year": "Anno",
  "season": "Stagione",
  "order_date": "Data creazione",
  "cancel_date": "Scadenza annullamento",
  "invoice_date": "Data della fattura",
  "invoice": "№ fattura",
  "invoice_url": "URL fattura",
  "download": "Download",
  "view": "Visualizza",
  "ship_date": "Data di spedizione richiesta",
  "expected_ship_date": "Data di spedizione prevista",
  "terms_name": "Nome del termine",
  "po_number": "N° ordine d'acquisto",
  "size": "Taglia",
  "alt_name": "Dimens. lunghezza",
  "upc": "UPC",
  "units": "Qtà",
  "amount": "Importo",
  "net_price": "Netto",
  "unit_price": "Prezzo per unità",
  "orders": "Ordini",
  "totals": "Totali",
  "details": "Dettagli",
  "open": "Apri",
  "shipped": "Inviato",
  "cancelled": "Cancellato",
  "canceled": "Cancellato",
  "released": "Rilasciato",
  "invoiced": "Fatturato",
  "picked": "Ritirato",
  "region": "Regione",
  "order_type": "Tipo ordine",
  "backorder_date": "Data ordine inevaso",
  "backorder": "Ordine inevaso",
  "payment_terms": "Condizioni di pagamento",
  "ship_via": "Spedisci Tramite",
  "line_discount": "Sconto della linea",
  "rma_number": "№ RMA",
  "cash_discount": "Sconto contanti",
  "hold_for_confirmation": "Trattieni per conferma",
  "credit_hold": "Trattieni credito",
  "order_notes": "Note sull'ordine",
  "product_series": "Serie del prodotto",
  "product_category": "Categoria del prodotto",
  "product_sub_category": "Sottocategoria del prodotto",
  "quantity_confirmed": "Confermato",
  "quantity_backordered": "Arretrati",
  "quantity_cancelled": "Cancellatos",
  "quantity_shipped": "Inviato",
  "ivendix_order_id": "iVendix ID",
  "catalog": "Catalogo",
  "source": "Fonte",
  "division": "Divisione",
  "actual_ship_date": "Data spedizione effettiva",
  "rep_name": "Nome rappresentanti",
  "tracking_carrier": "Codice corriere",
  "gross_amount": "Importo lordo",
  "discount_percent": "Sconto %"
})
);
