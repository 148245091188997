define(
  ({
    copy: 'Skopírovať',
    paste: 'Prilepiť',
    copyToAll: 'Skopírovať do všetkých',
    add_to_cart: 'Pridať do košíka',
    addToCart: 'Pridať do košíka',
    remove: 'Odstrániť',
    added: 'Pridané',
    dropped: 'Odstránené',
    units: 'Jednotky',
    total: 'Spolu',
    atsWarning: 'Toto množstvo nebude %{availableOn} dostupné.',
    inventory_warehouse: 'Sklad inventára',
    notInCatalog: 'Do košíka nemožno pridať položku',
    notInCatalogExplanation: 'Ľutujeme, ale pridávanie položiek naprieč katalógmi je nedostupné.',
    digital_market_notInCatalogExplanation: 'Váš košík môže súčasne obsahovať položky iba od jednej značky',
    removeFromCartSuggestion: 'Chcete <link>svoj košík vyprázdniť?</link>',
    digital_market_removeFromCartSuggestion: 'Ak chcete pridať túto položku, môžete <link>si vyprázdniť košík</link>',
    emptyCart: 'Vyprázdniť košík',
    empty: 'Prázdne',
    emptyConfirmation: 'Naozaj chcete košík vyprázdniť?',
    available_on: 'Dostupné',
    personalization: 'Prispôsobenie',
    personalize: 'Prispôsobiť',
    placement: 'Umiestnenie',
    content: 'Obsah',
    color: 'Farba',
    product_specification: 'Špecifikácia produktu',
    type: 'Typ',
    logo: 'Logo',
    text: 'Text',
    text_and_logo: 'Text, logo,',
    other: 'Ostatné',
    value_might_be_stale: 'Z dôvodu zmien vášho košíka môže byť táto hodnota neaktuálna. Ak to chcete prepočítať, uložte svoju objednávku.',
    more_details: 'Viac podrobností',
    availability: 'Dostupnosť',
    no_scheduled_availability: 'Žiadna plánovaná dostupnosť',
    not_available_until: 'Nedostupné do <date />',
    available_as_of: 'Dostupné k <date />',
    units_bundled_singular_label: '%{units} zoskupená jednotka',
    units_bundled_label: 'Zoskupené jednotky: %{units}',
    add_bundle_to_cart: 'Pridať skupinu do košíka',
    bundles: 'Skupiny',
    bundled: 'Zoskupené',
    product: 'Produkt',
    sizes_and_quantities: 'Veľkosť/množstvo',
    units_singular_label: '%{units} jednotka',
    units_label: 'Jednotky: %{units}',
    current_availability: 'Aktuálna dostupnosť',
    future_availability: 'Budúca dostupnosť',
    size: 'Veľkosť',
    sku: 'SKU',
    availability_date: 'Dátum dostupnosti',
    quantity: 'Množstvo',
    availability_subject_to_change: 'Dostupnosť sa môže zmeniť',
    yes: 'Áno',
    no: 'Nie',
    copy_to_all_confirmation_message: 'Naozaj chcete skopírovať tento rozsah veľkosti na všetky štýly nižšie?',
  }),
);
