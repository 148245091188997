define(
  ({
    no_data_message: 'Aucune entrée',
    order_number: 'Commande #',
    elastic_order_number: 'Commande Elastic #',
    line_number: 'Nº de ligne',
    customer_name: 'Nom du client',
    customer_number: 'Client #',
    location_number: 'N° de Livraison',
    location_name: "Nom de l'emplacement",
    product: 'Produit #',
    style_name: 'Nom du produit',
    code: 'Code couleur',
    color: 'Nom de la couleur',
    status: 'État de la ligne',
    states: 'État de la commande',
    tracking: 'N° de suivi',
    parcel_number: 'Colis #',
    year: 'Année',
    season: 'Saison',
    order_date: 'Date de création',
    cancel_date: 'Annuler par date',
    invoice_date: 'Date de facture',
    invoice: 'N° de facture',
    invoice_url: 'URL de la facture',
    download: 'Télécharger',
    view: 'Voir',
    ship_date: "Date d'expédition demandée",
    expected_ship_date: "Date d'expédition prévue",
    terms_name: 'Nom des conditions',
    po_number: 'BC #',
    size: 'Taille',
    alt_name: 'Longueur de la taille',
    upc: 'CUP',
    units: 'Qté',
    amount: 'Montant',
    net_price: 'Net',
    unit_price: 'Prix unitaire',
    orders: 'Commandes',
    totals: 'Total',
    details: 'Détails',
    open: 'Ouvrir',
    shipped: 'Expédié',
    cancelled: 'Annulé',
    canceled: 'Annulé',
    released: 'Publié',
    invoiced: 'Facturé',
    picked: 'Sélectionné',
    region: 'Région',
    order_type: 'Type de commande',
    backorder_date: 'Date de la commande en attente',
    backorder: 'Commande en attente',
    payment_terms: 'Conditions de paiement',
    ship_via: 'Expédier via',
    line_discount: 'Réduction de ligne de commande client',
    rma_number: "Numéro d'accord de retour",
    cash_discount: 'Réduction au comptant',
    hold_for_confirmation: 'Retenu pour confirmation',
    credit_hold: 'Recouvrement de crédit',
    order_notes: 'Notes de la commande',
    product_series: 'Série de produit',
    product_category: 'Catégorie de produit',
    product_sub_category: 'Sous-catégorie de produit',
    quantity_confirmed: 'Confirmé',
    quantity_backordered: 'En cours de réapprovisionnement',
    quantity_cancelled: 'Annulé',
    quantity_shipped: 'Expédié',
    ivendix_order_id: 'ID iVendix',
    catalog: 'Catalogue',
    source: 'Source',
    division: 'Division',
    actual_ship_date: "Date d'expédition effective",
    rep_name: 'Nom de réprésentant',
    tracking_carrier: 'Code de transporteur',
    gross_amount: 'Montant brut',
    discount_percent: '% de la réduction',
    warehouse_name: "Nom de l'entrepôt",
    discount_1: 'Remise 1',
    discount_2: 'Remise 2',
    discount_3: 'Remise 3',
  }),
);
