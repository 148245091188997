define(
  ({
    copy: 'Kopiuj',
    paste: 'Wklej',
    copyToAll: 'Kopiuj do wszystkich',
    add_to_cart: 'Dodaj do koszyka',
    addToCart: 'Dodaj do koszyka',
    remove: 'Usuń',
    added: 'Dodano',
    dropped: 'Usunięty',
    units: 'Jednostki',
    total: 'Łącznie',
    atsWarning: 'Ta ilość nie będzie dostępna do %{availableOn}.',
    inventory_warehouse: 'Magazyn zapasów',
    notInCatalog: 'Nie można dodać produktu do koszyka',
    notInCatalogExplanation: 'Przepraszamy, dodawanie produktów między katalogami nie jest dostępne.',
    digital_market_notInCatalogExplanation: 'Twój koszyk może zawierać tylko produkty jednej marki na raz',
    removeFromCartSuggestion: 'Czy chcesz <link>opróżnić koszyk?</link>',
    digital_market_removeFromCartSuggestion: 'Możesz <link>opróżnić koszyk</link>, aby dodać ten element',
    emptyCart: 'Opróżnij koszyk',
    empty: 'Pusty',
    emptyConfirmation: 'Czy na pewno chcesz opróżnić koszyk?',
    available_on: 'Dostępne w dniu',
    personalization: 'Personalizacja',
    personalize: 'Personalizuj',
    placement: 'Umiejscowienie',
    content: 'Treść',
    color: 'Kolor',
    product_specification: 'Parametry produktu',
    type: 'Typ',
    logo: 'Logo',
    text: 'Tekst',
    text_and_logo: 'Tekst, logo',
    other: 'Inny',
    value_might_be_stale: 'Z powodu zmian w koszyku ta wartość może być nieaktualna. Zapisz zamówienie, aby przeliczyć ponownie.',
    more_details: 'Więcej szczegółów',
    availability: 'Dostępność',
    no_scheduled_availability: 'Brak zaplanowanej dostępności',
    not_available_until: 'Niedostępne do <date />',
    available_as_of: 'Dostępne od <date />',
    units_bundled_singular_label: '%{units} jednostka zgrupowana',
    units_bundled_label: '%{units} jednostki zgrupowane',
    add_bundle_to_cart: 'Dodaj grupę do koszyka',
    bundles: 'Grupy',
    bundled: 'Zgrupowane',
    product: 'Produkt',
    sizes_and_quantities: 'Rozmiar / Ilość',
    units_singular_label: '%{units} jednostka',
    units_label: '%{units} jednostki',
    current_availability: 'Bieżąca dostępność',
    future_availability: 'Dostępność w przyszłości',
    size: 'Rozmiar',
    sku: 'SKU',
    availability_date: 'Data dostępności',
    quantity: 'Ilość',
    availability_subject_to_change: 'Dostępność może ulec zmianie',
    yes: 'Tak',
    no: 'Nie',
    copy_to_all_confirmation_message: 'Czy na pewno chcesz skopiować ten zakres rozmiarów do wszystkich poniższych stylów?',
  }),
);
