define(
  ({
    copy: 'コピー',
    paste: 'ペースト',
    copyToAll: 'すべてにコピー',
    add_to_cart: 'カートに追加',
    addToCart: 'カートに追加',
    remove: '削除',
    added: '追加されました',
    dropped: '削除済み',
    units: '個数',
    total: '合計',
    atsWarning: 'この数量は%{availableOn}までには入荷されません。',
    inventory_warehouse: '倉庫の在庫',
    notInCatalog: 'アイテムをカートに追加できません',
    notInCatalogExplanation: '申し訳ありませんが、カタログをまたいでアイテムを追加することはできません。',
    digital_market_notInCatalogExplanation: '一度にカートに含めることができるアイテムは、同一ブランドのアイテムに限られます',
    removeFromCartSuggestion: '<link>カートを空</link>にしますか？',
    digital_market_removeFromCartSuggestion: '<link>カートを空</link>にしてから、このアイテムを追加できます',
    emptyCart: 'カートを空にする',
    empty: '空にする',
    emptyConfirmation: 'カートを空にして本当によろしいですか？',
    available_on: '出荷可能日:',
    personalization: 'パーソナライゼーション',
    personalize: 'パーソナライズ',
    placement: '配置',
    content: 'コンテンツ',
    color: 'カラー',
    product_specification: '製品仕様',
    type: '種類',
    logo: 'ロゴ',
    text: 'テキスト',
    text_and_logo: 'テキスト、ロゴ',
    other: 'その他',
    value_might_be_stale: 'カートが変更されたため、この価格は最新ではない可能性があります。注文を保存して再計算してください。',
    more_details: 'その他の詳細',
    availability: '在庫',
    no_scheduled_availability: '在庫の入荷予定はありません',
    not_available_until: '<date />まで購入できません',
    available_as_of: '<date />の時点では購入可能です',
    units_bundled_singular_label: '%{units}個セット',
    units_bundled_label: '%{units}個セット',
    add_bundle_to_cart: 'セットをカートに追加',
    bundles: 'セット販売',
    bundled: 'セット販売',
    product: '商品',
    sizes_and_quantities: 'サイズ / 数量',
    units_singular_label: '%{units}個セット',
    units_label: '%{units}個セット',
    current_availability: '現在の在庫',
    future_availability: '今後の在庫状況',
    size: 'サイズ',
    sku: 'SKU',
    availability_date: '入荷予定日',
    quantity: '数量',
    availability_subject_to_change: '在庫状況は変更される場合があります',
    yes: 'はい',
    no: 'いいえ',
    copy_to_all_confirmation_message: '下のすべてのシステムにこのサイズ範囲をコピーして本当によろしいですか？',
  }),
);
