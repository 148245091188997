define(
({
  "internal_code": "Sisäinen koodi",
  "no_data_message": "Ei tallenteita",
  "order_number": "Tilaus #",
  "elastic_order_number": "Elastic-tilaus #",
  "line_number": "Rivi #",
  "customer_name": "Asiakkaan nimi",
  "customer_number": "Asiakas #",
  "location_number": "Sijainti #",
  "location_name": "Sijaintipaikan nimi",
  "product": "Tuote #",
  "style_name": "Tuotteen nimi",
  "code": "Värikoodi",
  "color": "Värin nimi",
  "status": "Rivin tila",
  "states": "Tilauksen tila",
  "tracking": "Seuranta #",
  "parcel_number": "Paketti #",
  "year": "Vuosi",
  "season": "Vuodenaika",
  "order_date": "Luontipäivä",
  "cancel_date": "Peruuta päivämäärän perusteella",
  "invoice_date": "Laskutuspäivä",
  "invoice": "Laskutus #",
  "invoice_url": "Laskun URL",
  "download": "Lataa",
  "view": "Näytä",
  "ship_date": "Pyydetty toimituspäivä",
  "expected_ship_date": "Oletettu toimituspäivä",
  "terms_name": "Ehtojen nimi",
  "po_number": "Postinumero",
  "size": "Koko",
  "alt_name": "Koon pituus",
  "upc": "UPC",
  "units": "Lkm",
  "amount": "Summa",
  "net_price": "Netto",
  "unit_price": "Yksikköhinta",
  "orders": "Tilaukset",
  "totals": "Summat",
  "details": "Lisätiedot",
  "open": "Avaa",
  "shipped": "Toimitettu",
  "cancelled": "Peruttu",
  "canceled": "Peruttu",
  "released": "Vapautettu",
  "invoiced": "Laskutettu",
  "picked": "Poimittu",
  "region": "Alue",
  "order_type": "Tilauksen tyyppi",
  "backorder_date": "Jälkitoimituksen päivämäärä",
  "backorder": "Jälkitoimitus",
  "payment_terms": "Maksuehdot",
  "ship_via": "Toimitustapa",
  "line_discount": "Rivin alennus",
  "rma_number": "RMA #",
  "cash_discount": "Raha-alennus",
  "hold_for_confirmation": "Pidätä vahvistusta varten",
  "credit_hold": "Luottopidätys",
  "order_notes": "Tilauksen muistiinpanot",
  "product_series": "Tuotesarjat",
  "product_category": "Tuotekategoria",
  "product_sub_category": "Tuotteiden alakategoria",
  "quantity_confirmed": "Vahvistettu",
  "quantity_backordered": "Jälkitoimitettu",
  "quantity_cancelled": "Peruttu",
  "quantity_shipped": "Toimitettu",
  "ivendix_order_id": "iVendix-tunniste",
  "catalog": "Katalogi",
  "source": "Lähde",
  "division": "Osa-alue",
  "actual_ship_date": "Varsinainen toimituspäivä",
  "rep_name": "Edustajan nimi",
  "tracking_carrier": "Lähettikoodi",
  "gross_amount": "Bruttosumma",
  "discount_percent": "Alennus %"
})
);
