define(
({
  "no_data_message": "記録がありません",
  "order_number": "注文番号",
  "elastic_order_number": "Elastic注文番号",
  "line_number": "ライン#",
  "customer_name": "顧客名",
  "customer_number": "顧客",
  "location_number": "ロケーション番号",
  "location_name": "ロケーション名",
  "product": "製品番号",
  "style_name": "製品名",
  "code": "カラーコード",
  "color": "カラー名",
  "status": "ラインステータス",
  "states": "注文のステータス",
  "tracking": "追跡番号",
  "parcel_number": "小包番号",
  "year": "年",
  "season": "シーズン",
  "order_date": "データ作成日",
  "cancel_date": "キャンセル受付期限日",
  "invoice_date": "請求日時",
  "invoice": "請求書番号",
  "invoice_url": "請求書URL",
  "download": "ダウンロード",
  "view": "見る",
  "ship_date": "リクエストされた発送日",
  "expected_ship_date": "発送予定日",
  "terms_name": "条件名",
  "po_number": "注文番号",
  "size": "サイズ",
  "alt_name": "サイズ 長さ",
  "upc": "UPC",
  "units": "個数",
  "amount": "金額",
  "net_price": "卸値",
  "unit_price": "単価",
  "orders": "注文",
  "totals": "合計",
  "details": "詳細",
  "open": "開く",
  "shipped": "発送済み",
  "cancelled": "キャンセル済み",
  "canceled": "キャンセル済み",
  "released": "リリース済み",
  "invoiced": "送り状作成済み",
  "picked": "ピッキング済み",
  "region": "地域",
  "order_type": "注文の種類",
  "backorder_date": "取り寄せ注文日",
  "backorder": "取り寄せ注文",
  "payment_terms": "お支払い条件",
  "ship_via": "発送方法",
  "line_discount": "行ごとの割引",
  "rma_number": "商品返品確認番号",
  "cash_discount": "現金割引",
  "hold_for_confirmation": "確認待ち中",
  "credit_hold": "クレジットホールド",
  "order_notes": "注文メモ",
  "product_series": "製品シリーズ",
  "product_category": "製品カテゴリー",
  "product_sub_category": "製品サブカテゴリー",
  "quantity_confirmed": "確認済み",
  "quantity_backordered": "入荷待ち",
  "quantity_cancelled": "キャンセル済み",
  "quantity_shipped": "発送済み",
  "ivendix_order_id": "iVendix ID",
  "catalog": "カタログ",
  "source": "ソース",
  "division": "部署",
  "actual_ship_date": "実際の発送日",
  "rep_name": "担当者名",
  "tracking_carrier": "輸送業者コード",
  "gross_amount": "総額",
  "discount_percent": "割引率 (%)"
})
);
