define(
  ({
    app_name: 'Elastic',
    none: '无',
    select: '选择',
    cancel: '取消',
    drop_ship_instructions: '仅国内配送。没有运送到APO或FPO。',
    product_data_sheet_tab_technology: '技术',
    product_data_sheet_tab_overview: '概览',
    product_data_sheet_tab_details: '详细信息',
    cart_submit_order_dealer: '下单',
    cart_submit_order_rep: '下单',
    erp_order_number: 'ERP订单号',
    sap_order_number: 'SAP订单号',
    nda_explanation: 'NDA说明。',
    terms_and_conditions_description: '勾选下方来表明您已经阅读并同意<a href="###" target="_blank">条款和条件</a>',
    discounted_total: '折扣总计',
    upcharge: '附加费用',
    dont_show_me_this_again: '不再显示。',
    okay: '确定',
    arrival_date: '开始发货日期',
    sso_message: '外部登录',
    alt_login_title: '登录',
    prebook: '预订',
    ats: 'ATS',
    alternate_images: '备用图片',
    choose: '选择',
    new_version_available_modal: '有可用的新版本',
    new_version_available_message: '应用有一个可用的新版本',
    new_version_reload: '加载新版本',
    new_version_snooze: '稍后提醒我',
    new_version_save_and_reload: '保存我的工作并重新加载',
    popup_may_be_suppressed_title: '支付页面已打开',
    popup_may_be_suppressed_description: '信用卡支付页面已打开。如果您没有看到，请在浏览器地址栏中找到并拦截的弹窗并允许。',
    popup_blocked_title: '弹窗已被拦截',
    popup_blocked_description: '提交支付信息的新窗口已被拦截。请检查浏览器设置和扩展并重试。如果问题依旧存在，您可以保存您的订单，并在其他浏览器中打开Elastic。较新版本的Google Chrome对Elastic的支持最好。',
    pay_by_credit_card: '用信用卡支付',
    do_you_want_to_pay_by_credit_card: '您想用信用卡支付吗？',
    pay_by_credit_card_no: '否',
    pay_by_credit_card_yes: '是',
    cancel_order_submission_and_payment: '取消订单提交和支付',
    product_reference_short: '参考号：%{reference}',
    product_reference_long: '上年度SKU：%{reference}',
    variation_code: '变体代码',
    pending_orders: '待处理订单',
    freight_discount_message: '此配送中的物品可享%{sales_program}配送折扣',
    partial_freight_discount_message: '为此配送中不可享受%{sales_program}配送折扣的物品选择配送方式',
    non_qualifying_items_ship_by_default_method: '不符合%{sales_program}条件的物品将通过默认方式配送',
    export_pdf: '导出PDF',
    ots: 'OTS',
    terms_and_conditions: '条款和条件',
    accept: '接受',
    back_to_orders: '回到订单',
    back_to_payments: '回到支付',
    back_to_shipments: '回到配送',
    required: '必填',
    send_request: '发送请求',
    should_not_empty: '不应为空',
    should_be_number: '应为数字',
    incorrect_value: '不正确的值',
    incorrect_phone: '电话号码应匹配国际格式+xx',
    success: '成功',
    error: '错误',
    invalid_phone_number: '电话号码无效',
    invalid_email: '邮箱无效',
    submit: '提交',
    success_message: '您的信息已成功提交',
    error_message: '提交您的信息时出错。如果此问题仍然存在，请重试或联系支持人员。',
    no_schema: '此功能尚未配置',
    reset: '重置',
    quick_entry_table_header_shipment_b2c: '配送',
    enter_quantities_segmented_control_b2c_by_shipment: '按配送',
    new_document_b2c_shipment: '配送',
    boxed_order: '已装箱订单',
    boxed_order_explanation_title: '已装箱订单',
    boxed_order_explanation: '向订单添加超过一个箱子后，数量将自动复制。 \n输入的数量将自动复制到输入的#个箱子。',
    boxes: '箱子',
    units_per_box: '每个箱子的单位数',
    regional: '地区性',
    purchase_order_tooltip: '输入的值无效。',
    tournament_date_is_too_late: '日期不可晚于%{date}',
    page_notes_error: '输入的值无效。',
    internet_connection_lost: '互联网连接已中断',
    internet_connection_lost_builder_description: '<p>您未连接网络。请重新连接以继续。您的文件已保存为最后一次手动或自动保存的版本。</p><p>如果担心数据丢失，请点击下方按钮来下载备份数据文件，并将文件转发至 <Link>support@elasticsuite.com</Link>来恢复您的订单。</p>',
    internet_connection_lost_description: '您当前未连接网络。请重新连接以继续。',
    download_backup_data_file: '下载备份数据文件',
    submit_order_description: '您的订单即将提交。一旦提交，它将被锁定，不再可编辑。请注意，订单将保存到云端。',
  }),
);
