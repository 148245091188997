define(
  ({
    copy: 'Copier',
    paste: 'Coller',
    copyToAll: 'Copier vers tous',
    add_to_cart: 'Ajouter au panier',
    addToCart: 'Ajouter au panier',
    remove: 'Retirer',
    added: 'Ajouté',
    dropped: 'Retiré',
    units: 'Unités',
    total: 'Total',
    atsWarning: "Cette quantité ne sera pas disponible d'ici le %{availableOn}.",
    inventory_warehouse: "Entrepôt d'inventaire",
    notInCatalog: "Impossible d'ajouter l'article au panier",
    notInCatalogExplanation: "Désolé, mais l'ajout d'articles à partir de plusieurs catalogues n'est pas disponible.",
    digital_market_notInCatalogExplanation: "Votre panier ne peut contenir que des articles d'une seule marque à la fois",
    removeFromCartSuggestion: 'Voulez-vous <link>vider votre panier ?</link>',
    digital_market_removeFromCartSuggestion: 'Vous pouvez <link>vider votre panier</link> pour ajouter cet article',
    emptyCart: 'Panier vide',
    empty: 'Vide',
    emptyConfirmation: 'Voulez-vous vraiment vider votre panier ?',
    available_on: 'Disponible sur',
    personalization: 'Personnalisation',
    personalize: 'Personnaliser',
    placement: 'Placement',
    content: 'Contenu',
    color: 'Couleur',
    product_specification: 'Spécifications du produit',
    type: 'Type',
    logo: 'Logo',
    text: 'Texte',
    text_and_logo: 'Texte, logo',
    other: 'Autre',
    value_might_be_stale: 'En raison de changements dans votre panier, cette valeur est peut-être obsolète. Enregistrez votre commande pour recalculer.',
    more_details: 'Plus de détails',
    availability: 'Disponibilité',
    no_scheduled_availability: 'Aucune disponibilité programmée',
    not_available_until: "Non disponible jusqu'au <date />",
    available_as_of: 'Disponible à partir du <date />',
    units_bundled_singular_label: '%{units} unité groupée',
    units_bundled_label: '%{units} unités groupées',
    add_bundle_to_cart: 'Ajouter le lot au panier',
    bundles: 'Lots',
    bundled: 'Groupé',
    product: 'Produit',
    sizes_and_quantities: 'Taille / Quantité',
    units_singular_label: '%{units} unité',
    units_label: '%{units} unités',
    current_availability: 'Disponibilité Actuelle',
    future_availability: 'Disponibilité Future',
    size: 'Taille',
    sku: 'UGS',
    availability_date: 'Date de Disponibilité',
    quantity: 'Qté.',
    availability_subject_to_change: 'La disponibilité est sujette à changement',
    yes: 'Oui',
    no: 'Non',
    copy_to_all_confirmation_message: 'Voulez-vous vraiment copier cette gamme de tailles dans tous les styles ci-dessous ?',
  }),
);
