define(
  ({
    copy: 'Kopiëren',
    paste: 'Plakken',
    copyToAll: 'Naar alles kopiëren',
    add_to_cart: 'Aan winkelwagen toevoegen',
    addToCart: 'Aan winkelwagen toevoegen',
    remove: 'Verwijderen',
    added: 'Toegevoegd',
    dropped: 'Vervallen',
    units: 'Eenheden',
    total: 'Totaal',
    atsWarning: 'Deze hoeveelheid is niet beschikbaar op %{availableOn}.',
    inventory_warehouse: 'Inventaris magazijn',
    notInCatalog: 'Kan artikel niet aan winkelwagen toevoegen',
    notInCatalogExplanation: 'Sorry, maar u kunt geen artikelen uit verschillende catalogus toevoegen.',
    digital_market_notInCatalogExplanation: 'Je winkelwagen kan alleen artikelen van hetzelfde merk bevatten',
    removeFromCartSuggestion: 'Wilt u uw <link>winkelwagen leegmaken</link>?',
    digital_market_removeFromCartSuggestion: 'Je kunt <link>je winkelwagen leeg maken</link> om dit artikel toe te voegen',
    emptyCart: 'Winkelwagen leegmaken',
    empty: 'Leegmaken',
    emptyConfirmation: 'Weet u zeker dat u uw winkelwagen wilt leegmaken?',
    available_on: 'Beschikbaar op',
    personalization: 'Personalisering',
    personalize: 'Personaliseren',
    placement: 'Plaatsing',
    content: 'Inhoud',
    color: 'Kleur',
    product_specification: 'Productgegevens',
    type: 'Type',
    logo: 'Logo',
    text: 'Tekst',
    text_and_logo: 'Tekst, Logo',
    other: 'Overig',
    value_might_be_stale: 'Vanwege wijzigingen aan uw winkelwagen, kan deze waarde achterhaald zijn. Sla uw bestelling op om opnieuw te berekenen.',
    more_details: 'Meer details',
    availability: 'Beschikbaarheid',
    no_scheduled_availability: 'Geen geplande beschikbaarheid',
    not_available_until: 'Niet beschikbaar tot <date />',
    available_as_of: 'Beschikbaar vanaf <date />',
    units_bundled_singular_label: '%{units} unit gebundeld',
    units_bundled_label: '%{units} units gebundeld',
    add_bundle_to_cart: 'Bundel aan winkelwagen toevoegen',
    bundles: 'Bundels',
    bundled: 'Gebundeld',
    product: 'Product',
    sizes_and_quantities: 'Maat/Hoeveelheid',
    units_singular_label: '%{units} unit',
    units_label: '%{units} units',
    current_availability: 'Huidige beschikbaarheid',
    future_availability: 'Toekomstige beschikbaarheid',
    size: 'Grootte',
    sku: 'SKU',
    availability_date: 'Datum beschikbaar',
    quantity: 'Hoev.',
    availability_subject_to_change: 'Beschikbaarheid is onderhevig aan wijzigingen',
    yes: 'Ja',
    no: 'Nee',
    copy_to_all_confirmation_message: 'Weet je zeker dat je dit maatbereik op alle onderstaande stijlen wilt toepassen?',
  }),
);
