define(
  ({
    copy: '복사',
    paste: '붙여넣기',
    copyToAll: '모두에 복사',
    add_to_cart: '카트에 추가',
    addToCart: '카트에 추가',
    remove: '제거',
    added: '추가됨',
    dropped: '취소됨',
    units: '단위',
    total: '합계',
    atsWarning: '이 수량은 %{availableOn}까지 확보할 수 없습니다.',
    inventory_warehouse: '재고품 창고',
    notInCatalog: '카트에 항목을 추가할 수 없음',
    notInCatalogExplanation: '죄송하지만, 카탈로그 간 항목 추가는 이용할 수 없습니다.',
    digital_market_notInCatalogExplanation: '카트에는 한 번에 1개 브랜드의 항목만 포함할 수 있습니다',
    removeFromCartSuggestion: '<link>카트를 비우시겠습니까?</link>',
    digital_market_removeFromCartSuggestion: '<link>카트를 비우면</link> 이 항목을 추가할 수 있습니다',
    emptyCart: '카트 비우기',
    empty: '비어 있음',
    emptyConfirmation: '카트를 비우시겠습니까?',
    available_on: '이용 가능',
    personalization: '개인화',
    personalize: '개인화',
    placement: '배치',
    content: '콘텐츠',
    color: '색깔',
    product_specification: '제품 사양',
    type: '유형',
    logo: '로고',
    text: '텍스트',
    text_and_logo: '텍스트, 로고',
    other: '기타',
    value_might_be_stale: '카트의 변경 사항 때문에, 이 값이 유효하지 않습니다. 주문을 저장해서 다시 계산하세요.',
    more_details: '추가 세부 정보',
    availability: '구입 가능 여부',
    no_scheduled_availability: '일정 상의 구입 가능 여부',
    not_available_until: '<date />까지 사용 불가',
    available_as_of: '<date /> 현재 사용 가능',
    units_bundled_singular_label: '%{units} 유닛 번들',
    units_bundled_label: '%{units} 유닛 번들',
    add_bundle_to_cart: '카트에 번들 추가',
    bundles: '번들',
    bundled: '번들 단위',
    product: '제품',
    sizes_and_quantities: '크기 / 수량',
    units_singular_label: '%{units} 유닛',
    units_label: '%{units} 유닛',
    current_availability: '현재 구입 가능 여부',
    future_availability: '향후 구입 가능 여부',
    size: '사이즈',
    sku: 'SKU',
    availability_date: '구입 가능 날짜',
    quantity: '수량',
    availability_subject_to_change: '구입 가능 여부는 변경될 수 있습니다',
    yes: '예',
    no: '아니요',
    copy_to_all_confirmation_message: '이 크기 범위를 아래 모든 스타일에 복사하시겠습니까?',
  }),
);
